
import {
  UserManager
} from 'oidc-client'
import sysConfig from '@/config'
var oidcMgr = null
export const mgr = () => {
  if (oidcMgr) {
    return oidcMgr
  }
  let oidcUrl = process.env.VUE_APP_OIDC_URL
  let { protocol,host } = location
  const url = protocol+'//' + host + '/callback'
  const mainHost = sysConfig.code || host.split('.')[0]

  var config = {
    authority: oidcUrl, 
    client_id: mainHost + "_iot_client", // 溯源 trace 物联网 iot
    redirect_uri: url,
    response_type: "id_token token",
    scope: "openid profile hnCloud",
    post_logout_redirect_uri: protocol + '//' + host,
    monitorSession:false
  };
  oidcMgr = new UserManager(config)
  oidcMgr.events.addAccessTokenExpired(function () {
 
    oidcMgr.signoutRedirect().then((resp) => {
      console.log('signed out', resp)
    }).catch((err) => {
      console.log(err)
    })
  })
  return oidcMgr
}
export default mgr
