let class2type = {
	"[object Array]": "array",
	"[object Boolean]": "boolean",
	"[object Date]": "date",
	"[object Function]": "function",
	"[object Number]": "number",
	"[object Object]": "object",
	"[object RegExp]": "regexp",
	"[object String]": "string",
	"[object Error]": "error"
};
export const _typeof = function(o) {
	return o == null ?
		String(o) :
		class2type[toString.call(o)] || "object"
}

export const trim = function(str) {
	if (str) return str.replace(/(^\s*)|(\s*$)/g, "");
	return str;
}

/**
 * 测试对象是否是窗口（有可能是Frame）
 */
export const isWindow = function(o) {
	return o != null && o == o.window;
}

/**
 * 测试对象是否是布尔（Boolean）类型值
 */
export const isBoolean = function(o) {
	return _typeof(o) == "boolean";
}

/**
 * 测试对象是否是字符串（String）类型值
 */
export const isString = function(o) {
	return _typeof(o) == "string";
}

/**
 * 测试对象是否是日期（Date）类型值
 */
export const isDate = function(o) {
	return _typeof(o) == "date";
}

/**
 * 测试对象是否是正则表达式（RegExp）
 */
export const isRegExp = function(o) {
	return _typeof(o) == "regexp";
}

/**
 * 测试传入的参数是否是一个 javscript 对象
 */
export const isObject = function(o) {
	return _typeof(o) == "object";
}

/**
 * 测试对象是否是数组（Array）
 */
export const isArray = function(o) {
	return _typeof(o) == "array";
}

/**
 * 测试对象是否是Error（Error）
 */
export const isError = function(o) {
	return _typeof(o) == "error";
}

/**
 * 测试对象是否是函数
 */
export const isFunction = function(o) {
	return _typeof(o) == "function";
}

/**
 * 判断对象是否是数值类型
 */
export const isNumber = function(o) {
	return _typeof(o) == "number";
}

/**
 * 判断对象是否为空(Null)值
 */
export const isNull = function(o) {
	return o === null;
}

/**
 * 判断对象是否为 "未定义" 值(即 undefined)
 */
export const isUndefined = function(o) {
	return o === undefined || typeof o === "undefined";
}

/**
 * 判断对象是否为 "未定义" 值(即 undefined)或空(Null)值
 */
export const isNullOrUndefined = function(o) {
	return isNull(o) || isUndefined(o);
}

export const isPlainObject = function(o) {
	if (!o || _typeof(o) !== "object" || o.nodeType || isWindow(o)) {
		return false;
	}
	try {
		// Not own constructor property must be Object
		if (o.constructor &&
			!hasOwn.call(o, "constructor") &&
			!hasOwn.call(o.constructor.prototype, "isPrototypeOf")) {
			return false;
		}
	} catch (e) {
		// IE8,9 Will throw exceptions on certain host objects #9897
		return false;
	}
	return true;
}

/**
 * 判断传入的字符串是否为Null或者为空字符串
 */
export const isNullOrEmpty = function(str) {
	return str === undefined || str === null || str === "";
}

/**
 * 判断传入的字符串是否为Null或者为空字符串或者全是空格 
 */
export const isNullOrWhiteSpace = function(str) {
	return isNullOrEmpty(str) || trim(str) === ""
}

export const isEmptyObject = function(obj) {
	if (!obj) return false;
	for (var name in obj) {
		return false;
	}
	return true;
}

/**
 * 测试对象是否为空（不包含任何属性的空对象、null、undefined、空字符串、全空格）。
 * 这个方法既检测对象本身的属性，也检测从原型继承的属性（因此没有使用hasOwnProperty）。
 */
export const isEmptyObjectOrNull = function(obj) {
	switch (_typeof(obj)) {
		case "string":
			return isNullOrWhiteSpace(obj);
		case "array":
			return obj.length === 0;
		case "date":
			return Date.parse(obj) === 0;
		case "object":
			return isEmptyObject(obj);
	}
	return obj == null || obj == undefined;
}

const hasOwnProperty = Object.prototype.hasOwnProperty;
export const hasOwn = function(obj, key) {
	return hasOwnProperty.call(obj, key);
};

export default {
	_typeof,
	isWindow,
	isBoolean,
	isString,
	isError,
	isDate,
	isRegExp,
	isObject,
	isArray,
	isFunction,
	isNumber,
	isNull,
	isUndefined,
	isNullOrUndefined,
	isPlainObject,
	isNullOrEmpty,
	isNullOrWhiteSpace,
	isEmptyObject,
	isEmptyObjectOrNull,
	hasOwn,
};
