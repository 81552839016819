import axios from 'axios'
import { getToken } from '@/libs/util.js'
import router from '@/router'
import { Message } from 'element-ui';
import mgr from "@/libs/oidc.js";
let mgrInfos = mgr()
class HttpRequest {

  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }
  //  请求参数配置
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {}
    }
    return config
  }
  //  请求拦截器
  interceptors (instance) {
    // 请求拦截
    instance.interceptors.request.use(config => {

      if (!config.url.includes('qweather.com') && !config.url.includes('GetWebSiteCfg')) {

        let token = getToken()
        if (token) {
          token = token.replace(/\"/g, "")
          config.headers.Authorization = 'Bearer ' + token
        }

      }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {

      const { data, config } = res
      const message = data.errmsg || 'Network error';
      let showHeight = (document.documentElement.clientHeight / 2) - 50
      const filtter = [
        'https://geoapi.qweather.com/v2/city/lookup',
        'https://devapi.qweather.com/v7/weather/now',
        'https://devapi.qweather.com/v7/indices/1d',
        'https://devapi.qweather.com/v7/weather/7d',
        'https://devapi.qweather.com/v7/warning/now'
      ]
      // const vailList = ['/Auth/Account/LoginForNoVerify', '/Auth/Account/EntRegister']
      // const isLogin =  vailList.indexOf(config.url) != -1  ? true : false
      const isWeather = !!filtter.includes(config.url)

      if (
        (data.hasOwnProperty('errcode') && data.errcode !== 0)
        && !isWeather
      ) {
        Message({
          message: message,
          type: 'error',
          offset: showHeight || 50,
          duration: 2000
        })
        return false
      } else if (data.errcode !== 0) {
        return data
      }
      return data.data || data
    }, error => {

      let errorInfo = error.response
      let showHeight = (document.documentElement.clientHeight / 2) - 50
      if (!errorInfo) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
      }
      if (errorInfo.status == 401) {
        localStorage.clear();
        mgrInfos.signoutRedirect();
      } else {
        router.push({
          path: '/error'
        })
        return Promise.reject(errorInfo)
      }
    })
  }
  //  发起axios请求
  request (options) {

    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)

    this.interceptors(instance, options.url)

    return instance(options)
  }
}
export default HttpRequest