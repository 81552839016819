import store from '@/store'
export const dictionary = (key , data = {value: 'value', label: 'label'}) => {
   let getDictionaryList = store.state.user.dictionary
   if(getDictionaryList && getDictionaryList.hasOwnProperty(key)){
       return Object.entries(getDictionaryList[key]).map(([key,val]) => {
           let obj = new Object
           let handleKey = parseInt(key)
           if(data && typeof data === 'object') {
              data.hasOwnProperty('value')? obj[data.value] = handleKey : obj.value = handleKey
              data.hasOwnProperty('label')? obj[data.label] = val : obj.label = val
           }else {
              obj.value = handleKey
              obj.label = val 
           }
           return obj
       })   
   }else{
      
       return []
   }
}

export const formatter = (rowData,typeKey,multiple = false) => {

    let label= ''
    dictionary(typeKey).map(item => {
        if(multiple){
           //   多选模式
          if((rowData & item.value) !== 0){
          
             label += item.label + ','
          }
        }else{
          //  单选模式  
          if(item.value == rowData) label = item.label 
        }
        
    })
    
    return label || ''
}

export const company = (key) => {
    const company = store.state.user.companyInfo
    if( company ){
        if(company.hasOwnProperty(key)){
            return company[key]
        }
    }
    return ''
}


