import api from '@/api'
import {setTheme} from '@/assets/theme/setTheme.js'
import {
  getToken,
  saveLocal,
  IcoCreate
} from '@/libs/util'
import mgr from "@/libs/oidc.js";
import config from '@/config'
const user = {

  state: {
    token: getToken(),
    info: {},
    userName: '',
    hasGetInfo: false,
    userInfo: null,
    userPhoto: "",
    companyInfo: null,
    dictionary: null,
    menuList: [],
    dicColor: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    // 更新state数据
    setStateAttr(state, param = {}) {
      for (let key in param) {
        state[key] = param[key];
      }
    },
    setUserPhoto(state, userPhoto) {
      state.userPhoto = userPhoto
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setHasGetInfo(state, status) {
      state.hasGetInfo = status
    },
    setUserName(state, name) {
      state.userName = name
    },
    setCompanyInfo(state, companyInfo) {
      state.companyInfo = companyInfo
    },
    setDictionary(state, dictionary) {
      state.dictionary = dictionary
    },
    setMenu(state,menuInfo) {
         state.menuList = menuInfo
    }
  },
  actions: {
    // 获取用户信息
    async getUserInfo({commit, state, dispatch}) {
      if (!state.companyInfo) {
        dispatch('getWebSiteCfg')
      }
      try {
        const res = await Promise.all([api.GetUserInfo(), api.SysDictionaryList(),api.IotUserMenuItem(),api.getDicColor()])
        if (res && res.length){
          let [ userInfo , dicInfo, menuInfo,dicColor] = res
          dicInfo.putType = {}
          dicInfo.outType = {}
          Object.entries(dicInfo['outInTypes']).forEach(([key, val]) => {
            if (parseInt(key) <= 255) {
              dicInfo.putType[key] = val
            } else {
              dicInfo.outType[key] = val
            }
          }) //分散出入库
          commit('setUserName', userInfo.username || '')
          commit('setUserPhoto', userInfo.headUrl || '')
          commit('setUserInfo', userInfo || '')
          commit('setHasGetInfo', true)
          commit('setDictionary', dicInfo)
          state.dicColor = dicColor
          if(menuInfo && menuInfo.length){
            if(menuInfo[0].children.length){
              let result = menuInfo[0].children[0].children || []
              commit('setMenu',result) 
            } 
          }
          
        }
        return res
      }catch {

        return Promise.reject()
      }
     
    },
    // 获取系统站点信息
    async getWebSiteCfg({
      commit
    }) {
      try {
       const res = await api.GetWebSiteCfg({appType: 4,code: config.code})
        if(res){
          commit('setCompanyInfo', res)
          window.document.title = res.title || ''
          if(res.favicon)  IcoCreate(res.favicon)
        }
        return res
      } catch {
        return false
      }
    
    },
    // 登录
    async handleLogin({
      commit
    }, data) {

      try {
        const res = await mgr().getUser()
   
        if ( res ){
          let accessToken = res.access_token
          commit('setToken', accessToken)
          saveLocal('token', accessToken)
        } else {
          // 重新登录
          mgr().signinRedirect().catch(data => {
           
          })
          return false
        }

        return true

      } catch {
        return false
      }
    },
    // 设置用户数据
    SetUserData({commit}, info) {
      commit('setStateAttr', {info})
      // 设置主题
      setTheme(info.theme);
    },
    // 改变用户主题
    ChangeTheme({dispatch, state}, theme) {
      const {info} = state;
      info.theme = theme;
      dispatch('SetUserData', info);
      // 再将该用户选的主题存到数据库（不方便mock所以省略）
    }
  }
}

export default user
