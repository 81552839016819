import config from '@/config'
import {isObject,hasOwn } from './type'
// 全局引入文件
export const importAll = context => {
  const map = {}
  let tmp = {}
  for (const key of context.keys()) {
    const keyArr = key.split('/')
    keyArr.shift() // 移除.
    map[keyArr.join('.').replace(/\.js$/g, '')] = context(key)
  }
  for (let key in map) {

    tmp = {
      ...tmp,
      ...map[key]
    }
  }
  return tmp
}
// 动态更新ico
export const IcoCreate = (icoUrl) => {
  let url = imgAddHost(icoUrl)
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link);
}
//  通过本地获取token
// export const getToken = () => {
//   let token = config.defaultToken
//   if(!process.env.NODE_ENV === 'development'){
//     token = getLocal('token')
//     token = token ? token.replace(/\"/g, "") : ''
//   }
//   return token
// }
//  通过本地获取token
export const getToken = () => {
  const token = getLocal('token')
  if (token) return token
  else return false
}

// 获取本地数据
export const getLocal = (name) => {

  return window.localStorage.getItem(name)
}

// 保存本地数据
export const saveLocal = (name, data) => {
  let handledata = typeof data === 'string' ? data : JSON.stringify(data)
  if (handledata) {
    localStorage.setItem(name, handledata)
  }

}
export const mapTo = (from, to) => {
  if (isObject(from) && isObject(to)) {
    Object.keys(to).forEach(key => {
      if (hasOwn(from, key)) {
        to[key] = from[key];
      }
    })
  }
}
/**
 * devId devCode 拼接转换
 * * @param {object || string} data {object 拼接code和id,string 分解code和id}
 */
export const devCodeAndId = data => {
  function isDataProperty (key) {
    return data.hasOwnProperty(key)
  }
  if (!data) return false
  if (typeof data === 'string' && data.includes('&')) {
    const arr = data.split('&')
    let devId = arr[0], devCode = arr[1]
    return {
      devId,
      devCode
    }

  }

  if (typeof data === 'object' && isDataProperty('devId') && isDataProperty('devCode')) {

    return `${data.devId}&${data.devCode}`
  }
  return false
}
export const splicingActiveMenu = data => {
  function isDataProperty (key) {
    return data.hasOwnProperty(key)
  }
  if (!data) return false
  if (typeof data === 'string' && data.includes('&')) {
    const arr = data.split('&')
    let devId = arr[0], devCode = arr[1], id = arr[2]
    return {
      devId,
      devCode,
      id
    }

  }

  if (typeof data === 'object' && isDataProperty('devId') && isDataProperty('devCode') && isDataProperty('id')) {

    return `${data.devId}&${data.devCode}&${data.id}`
  }
  return false
}
/**
 *  换行列表数据， 缺位补全
 * * @param {array || length} data {列表数据,一行单元数}
 */
export const completionRow = (list, i) => {
  let length = list.length, remainderNub = 0, result = [];
  if (length <= i) {
    remainderNub = i - length
  } else {
    remainderNub = i - (length % i)
  }
  if (remainderNub > 0) {
    for (let j = 0; j < remainderNub; j++) {
      result.push(j)
    }
  }
  return result
}
// 深拷贝对象
export const deepClone = obj => {
  const _toString = Object.prototype.toString

  // null, undefined, non-object, function
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  // DOM Node
  if (obj.nodeType && 'cloneNode' in obj) {
    return obj.cloneNode(true)
  }

  // Date
  if (_toString.call(obj) === '[object Date]') {
    return new Date(obj.getTime())
  }

  // RegExp
  if (_toString.call(obj) === '[object RegExp]') {
    const flags = []
    if (obj.global) {
      flags.push('g')
    }
    if (obj.multiline) {
      flags.push('m')
    }
    if (obj.ignoreCase) {
      flags.push('i')
    }

    return new RegExp(obj.source, flags.join(''))
  }

  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {}

  for (const key in obj) {
    result[key] = deepClone(obj[key])
  }

  return result
}

function dataURLtoBlob (base64Str, mimeTypeStr) {
  var bstr = atob(base64Str),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mimeTypeStr
  });
}
//  属性是否存在对象上

// export const hasOwn = (obj, name) => {
//   return Object.prototype.hasOwnProperty.call(obj, name);
// }
function downloadFile (hrefUrl, fileName) {
  var a = document.createElement("a")
  a.setAttribute("href", hrefUrl)
  a.setAttribute("download", fileName)
  a.setAttribute("target", "_blank")
  let clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent("click", true, true);
  a.dispatchEvent(clickEvent);
}
// 导出文件流
export const downloadFileByBase = (base64Str, fileName, mimeTypeStr = "application/vnd.ms-excel") => {
  var myBlob = dataURLtoBlob(base64Str, mimeTypeStr)
  var myUrl = URL.createObjectURL(myBlob)
  downloadFile(myUrl, fileName)
}
/**
 * @param {String} img url路径
 * @description  增加url host地址
 */
export const imgAddHost = (img) => {

  if (img) {
    if (
      img.indexOf("data:image") == 0 || img.search(/http[s]{0,1}:\/\/([\w.]+\/?)\S*/) >= 0 || !config.IpUrl
    ) {
      return img;
    } else {
      let baseUrl = config.IpUrl.replace(/\/+$/gm, "") + img;
      return baseUrl;
    }
  }
  return ''

}
// url链接是否有效
export const checkImgExists = imgurl => {
  return new Promise(function (resolve, reject) {
    var ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = function (res) {
      resolve(res)
    }
    ImgObj.onerror = function (err) {
      reject(err)
    }
  })
}
// 彩色图标处理
export const colourIocnName = icon => {
  if (icon && icon.includes(' ')) {
    let iconStr = icon.split(' ')
    return `#${iconStr[1]}`
  }
  return ''
}
/**
 * @param {Function} func 目标函数
 * @param {Number} delay  定时时间
 * @description  防抖函数 （持续输入更新定时器，直到最后一次执行函数）
 * @returns {Function}
 */
export const debounce = (func, delay) => {

  let clearTime
  return function () {
    clearTimeout(clearTime)
    clearTime = setTimeout(() => {

      func.apply(this, arguments)
    }, delay)
  }
}

// 获取父子节点信息
export const getParentChildInfo = (dataList,targeId,options = { childKey: 'children',value: 'value',label: 'label'}) => {
  let { childKey ,value} = options
  
  function funs(list,parent,result= {}){

      list.map(item => {
        if(item[childKey] && item[childKey].length){
  
            funs(item[childKey],item,result)
        }else{

          if( targeId == item[value] && parent){

              result.parentInfo= parent
              result.childrenInfo= item

          }
        }
      })
      return result
  }
  let result = funs(dataList)
  return result
}

export const todo = (arr,id)=> {
  let index = 1,newArr = [], length = arr.length;
  for(var i = 0; i < length; i++){
      if(arr[i].devId == id){
          newArr[0] = arr[i]
      }else{
          newArr[index++] = arr[i]
      }
  }

  return newArr

}