<template>
    <div class="dateSearch_box">
        <el-date-picker
            v-model="searchDateVal"
            @change="searchDateChange"
            class="date_picker_box"
            type="daterange"
            align="right"
            size="medium"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        >
        </el-date-picker>
        <ul class="boxsubBg dateType_box">
                <li 
                :class="[ timeType == item.code? 'active':'', 'weather-cp']"
                v-for="item of handleDate"
                :key="item.code"
                @click="timeChange(item.code)"
                >{{ item.name }}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'HnDateSearch',
        props:{
           timeList:{
               type: Array,
               default:()=> [
                   {
                       name: '今天',
                       code: 1
                   },
                    {
                       name: '近三天',
                       code: 2
                   },
                   {
                       name: '近七天',
                       code: 3
                   } 
               ]
            },
            defaultData:{
                type:[ Array , Number]
            },
            isPage:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                searchDateVal:'',
                timeType: 1,
             
            }
        },
        created(){
           if(this.defaultData){
               if(typeof this.defaultData == 'array'){
                   this.searchDateVal = this.defaultData
                   return
               }
               if(typeof this.defaultData == 'number' && this.defaultData > 0){
                   this.timeType = this.defaultData
               }
           }else{
                let timeListLength = this.handleDate.length  
                if(timeListLength) {
                    this.timeType =  this.handleDate[timeListLength - 1].code
                }
           }
        },
        computed:{
           handleDate(){
               if(this.isPage) {
                  let isHasAllVal = this.timeList.some(item=> item.code == 0)
                  if(!isHasAllVal) return this.timeList.concat([{name: '全部',code: 0}])
               }
               return this.timeList
           }
        },
        methods:{
           timeChange(code){
               let page = this.isPage? { pageIndex: 1 } : {}
               let data = {
                   sTime: '',
                   eTime: '',
                   timeType: code,
               }
               Object.assign(data,page)
               this.searchDateVal= ''
               this.timeType = code
               this.$emit('update',data)
           },
           searchDateChange(date){
              
               if(date && date.length == 2){
                   let page = this.isPage? { pageIndex: 1 } : {}
                   let data = {
                        sTime: date[0],
                        eTime: date[1],
                        timeType: null,
                   }
                   Object.assign(data,page)
                   this.timeType = -1
                   this.$emit('update',data)
               }else {
                   let code = this.defaultData || this.timeList[0] ?.code
                   this.timeChange(code)
               }
               
           }
        }
    }
</script>

<style lang="scss" scoped>
.dateSearch_box{
     display: flex;
    align-items: center; 
    .date_picker_box{
        margin-right: 15px;
    }
    ul.dateType_box{
        display: flex;
        //    width: 214px;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        color: #fff;
        border-radius: 17px;
        
        
        & > li {
            padding: 0 28px;
            border-radius: 17px;
            
        }
        & > li:not(:last-child){
            margin-right: 8px;
        }
    
    }
}
</style>