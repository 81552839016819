
// 先加载所有的主题(default在main.scss中加载，因为default主题对body没有使用class，可以应用在登陆页上)
// import './dark/style.scss';
import { getLightColor, getDarkColor } from "@/libs/theme";
// 默认主题
export const defaultTheme = 'default';
export const themeList = [
  {name: '深黑', key: 'dark'},
  {name: '浅灰', key: 'gray'}
];
// const primaryColor= '#00C395'
const isDark = false
export const setTheme = (themeName = defaultTheme) => {
  if (themeName === 'default') {
    if (document.body.className) document.body.className = ''
  } else {
    // 把该主题的所有属性存到缓存
    document.body.className = themeName;
  }
  changePrimary('#00C395')
}

  // 修改主题颜色
const changePrimary = (val) => {
 
    // 为了兼容暗黑模式下主题颜色也正常，以下方法计算主题颜色 由深到浅 的具体颜色
    document.documentElement.style.setProperty("--el-color-primary", val);
    document.documentElement.style.setProperty(
      "--el-color-primary-dark-2",
      isDark
        ? `${getLightColor(val, 0.2)}`
        : `${getDarkColor(val, 0.3)}`
    );
    // 颜色加深或变浅
    for (let i = 1; i <= 9; i++) {
      document.documentElement.style.setProperty(
        `--el-color-primary-light-${i}`,
        isDark
          ? `${getDarkColor(val, i / 10)}`
          : `${getLightColor(val, i / 10)}`
      );
    }
};