<template>
    <div class="hn_content_box wh100">
        
            <div class="left_box">
               <slot name="left"></slot>
            </div>
            <template v-if="hasData">
                <div class="right_box">
                <slot name="right"></slot>
                </div>
            </template>
            <template v-else>
                <div class="notData_box boxBg">
                  <hn-emptyData text= '暂无数据' size= '200px' style="height: 100%" setType= 'col'></hn-emptyData>
                </div>
            </template>
    </div>
</template>

<script>
    export default {
        name: 'HnContent',
        props: {
           hasData:{
               type: Boolean,
               default: true
           } 
        }
    }
</script>

<style lang="scss" scoped>
 .hn_content_box{
     display: flex;
    box-sizing: border-box;
    padding: 20px 20px 0 20px;
    .left_box{
       width: 270px;
       height: 100%;
       flex-shrink: 0;
    }
    .right_box{
       flex: 1;
       height: 100%;
       min-width: 0;
       margin-left: 10px;
       box-sizing: border-box;
    }
    .notData_box{
       flex: 1;
       height: 100%;
       border-radius: 8px;
       margin-left: 10px;
       box-sizing: border-box;
    }
 }
</style>