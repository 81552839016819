
import {Message} from 'element-ui';
let showHeight = (document.documentElement.clientHeight / 2) - 50
window.onresize = () => {
    return (() => {
        window.fullHeight = document.documentElement.clientHeight 
        showHeight = (window.fullHeight / 2) - 50;  // 高
    })()
};
const showMessage = Symbol('showMessage');
class DonMessage {
    success (options, single = true) {
        this[showMessage]('success', options, single)
    }
    warning (options, single = true) {
        this[showMessage]('warning', options, single)
    }
    info (options, single = true) {
        this[showMessage]('info', options, single)
    }
    error (options, single = true) {
        this[showMessage]('error', options, single)
    }

    [showMessage] (type, options, single) {
        if (single) {
            // 判断是否已存在Message
            if (document.getElementsByClassName('el-message').length === 0) {
                // 修改参数
                if(type === 'error' || type === 'warning'){
                    options = {
                        message: options,
                        offset:  showHeight,
                        duration: 2000,
                        showClose: true
                    };
                } else {
                    options = {
                        message: options,
                        offset:  showHeight,
                        duration: 1500,
                        showClose: true
                    };
                }
                Message[type](options)
            }
        } else {
            options = {
                message: options,
                duration: 1500,
                showClose: true
            };
            Message[type](options)
        }
    }
}
export const newMessage = new DonMessage();