import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'
import {
  getToken
} from '@/libs/util'

import mgr from "@/libs/oidc.js";
Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/homeIndex',
    children: [{
        path: '/homeIndex',
        name: 'homeIndex',
        component: () => import('../views/pages/indexHome/index.vue')
      },
      {
        path: '/monitoring',
        name: 'monitoring',
        component: () => import('../views/pages/monitoring')
      },
      {
        path: '/control',
        name: 'control',
        component: () => import('../views/pages/control')
      },
      {
        path: '/video',
        name: 'video',
        component: () => import('../views/pages/video')
      },
      {
        path: '/intelligentCtl',
        name: 'intelligentCtl',
        component: () => import('../views/pages/control/intelligentCtl.vue')
      },
      {
        path: '/spore',
        name: 'spore',
        component: () => import('../views/pages/spore/index.vue')
      },
      {
        path: '/pictrue',
        name: 'pictrue',
        component: () => import('../views/pages/pictrue')
      },
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('../views/pages/analysis')
      },
      {
        path: '/Insect',
        name: 'Insect',
        component: () => import('../views/pages/insect')
      },
      {
        path: '/historyInsect',
        name: 'historyInsect',
        component: () => import('../views/pages/insect/components/historyInsect.vue')
      },
      {
        path: '/intelligentPest',
        name: 'intelligentPest',
        component: () => import('../views/pages/intelligentPest')
      },
      {
        path: '/devControl',
        name: 'devControl',
        component: () => import('../views/pages/dev-control')
      },
      {
        path: '/electronicFence',
        name: 'devControl',
        component: () => import('../views/pages/electronicFence')
      }
      // {
      //   path: '/helpcontent',
      //   name: 'helpcontent',
      //   component: () => import('../views/pages/helpcontent')
      // }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Page404.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/networkError.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: {
      title: ''
    },
    component: () => import('@/views/callback')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册',
      hideInMenu: true
    },
    component: () => import('@/views/register')
  },
  {
    path: '/',
    component: () => import('@/views/basePage/inedx.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const whitelist = ['404','callback','error']
router.beforeEach(async (to, from, next) => {

  if (whitelist.includes(to.name)) {
    next()
  } else {

    // 当页面跳转时，验证是否登录，如果未登录，会自动重新跳转登录页
    // const isLogin = await store.dispatch('handleLogin')
    let token = getToken()
    //  当登录成功 ，根据用户状态进行通过路由
    if (token) {
      //  当用户信息存在，通过路由
      if (store.state.user.hasGetInfo) {

        next()

      } else {

        // 用户信息不存在，更新用户信息，主要用于页面刷新，vuex状态丢失时使用
        store.dispatch('getUserInfo').then(data => {

          if (data) {
            next()
          } else {
            next('/404')
          }
        })
      }
    }else{
      mgr().signinRedirect()
    }


  }
})
export default router