<template>
  <el-dialog 
  :id="dialogId" 
  custom-class="hn-dialog" 
  :append-to-body='false' 
  :class="{'custom-dialog-wrapper':!this.fullscreen && isCenter,'fixedWidth':isfixed,'dark_custom_box':true,'allRadius':!isBut}" 
  :width="dynamicWidth" :title="title" 
  :close-on-click-modal="maskClosable" 
  :visible.sync="dialogVisible" 
  :fullscreen="fullscreen" 
  :lock-scroll="true" 
  v-loading="loading" 
  v-dialogDrag='isDialogDrag' 
  :element-loading-text="loadingText" 
  @closed="close">
    <div class="dialog-body">
      <slot>内容</slot>
    </div>
    <template v-if="isBut">
      <div class="dialog-footer" slot="footer">
        <!-- @submit.native.prevent -->
        <el-button class="butbox" v-show="showCloseButton" size="mini" @click="close" plain>{{ cancelButtonText }}</el-button>
        <el-button class="butbox" v-show="showConfirmButton" @keyup.native.prevent @keydown.enter.native.prevent type="primary" size="mini" :disabled="buttonDisabled" :loading="confirmButtonLoading" @click="ok" plain>{{ confirmButtonText }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { debounce } from "@/libs/util";
let _query = debounce((vm, callBack) => {
  vm.queryFunc(callBack);
}, 500);
export default {
  props: {
    title: {
      type: String,
      default: "提示",
    },
    width: {
      type: String,
      default: "50",
    },
    isCenter:{
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    confirmButtonLoading: {
      type: Boolean,
      default: false,
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: "保 存",
    },
    cancelButtonText: {
      type: String,
      default: "取 消",
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    isDialogDrag: {
      type: Boolean,
      default: false,
    },
    isBut: {
      type: Boolean,
      default: true,
    },
    onOpen: Function,
    onBeforeClose: Function,
    onClose: Function,
    onOk: Function,
  },
  watch: {
    dialogVisible (val) {
      if (val) {
        this.handleOpen();
      } else {
        this.handleClose();
      }
    },
    width (val) {
      const dynamicWidth = this.handleWidth(document.body.clientWidth);
      this.dynamicWidth = dynamicWidth;
    },
  },
  computed: {
    buttonDisabled () {
      return this.confirmButtonDisabled || this.loading;
    },
    footerHide () {
      return !this.showCloseButton && !this.showConfirmButton;
    },
  },

  created () {
    const dynamicWidth = this.handleWidth(document.body.clientWidth);
    this.dynamicWidth = dynamicWidth;
    window.onresize = () => {
      this.query(() => {
        const currentWidth = document.body.clientWidth;
        this.dynamicWidth = this.handleWidth(currentWidth);
      });
      // 通知更新
    };
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      loadingText: "拼命加载中...",
      dialogId: "dialog-" + new Date().getTime(),
      dynamicWidth: "",
      isfixed: false,
    };
  },
  methods: {
    query (c) {
      _query(this, c);
    },
    queryFunc (c) {
      c()
    },
    handleWidth (cw) {
      let width = this.width
      if (width.includes('px'))
        return width
      const baseClient = 170
      let c = Math.round((cw / 10))
      width = parseInt(width)

      if (width > 30 && c < 170) {
        // 弹窗宽 大于 30， 屏幕尺寸小于 1700px时
        let y = width - 30
        let z = baseClient - c
        if (y < z) {
          // let maxWidth =  10
          // if(y <= 5){
          //   maxWidth = y*2
          // }else if( y > 5 && y <= 10){
          //    maxWidth = y + 4
          // }else if(y > 10 && y <= 15){
          //    maxWidth = y + 5
          // }
          let data = ((z - y) / 2)
          width += data > 15 ? 15 : data

        }
      } else if (width <= 30) {

        this.isfixed = true
        return ''
      }
      return `${width}%`
    },
    handleOpen () {
      if (this.onOpen) {
        this.$nextTick(() => {
          this.onOpen();
        });
      }
    },
    handleBeforeClose (done) {
      let cancel = false;
      if (this.onBeforeClose) {
        cancel = this.onBeforeClose() === true;
      }
      if (!cancel) done();
    },
    handleClose () {
      if (this.onClose) {
        this.onClose();
      }
    },
    open () {
      this.dialogVisible = true;
    },
    close () {
      this.handleBeforeClose(() => {
        this.dialogVisible = false;
      });
    },
    ok () {
      if (this.onOk) {
        this.query(() => {
          this.onOk();
        })

      } else {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss">
/*垂直居中 */
.custom-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .hn-dialog {
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 !important;
  }
}

.custom-dialog-wrapper {
  display: flex;
  flex-direction: column;
  // margin: 0 !important;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  /*height:600px;*/
  .el-dialog__body {
    flex: 1;
    overflow: auto;
    padding: 5px 20px 5px 20px !important;
  }
  .dialog-body {
    position: relative;
  }
  .dialog-footer {
    .butbox {
      width: 80px;
    }
  }
}

</style>