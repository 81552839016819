import HnContent from './HnContent'
import HnEmptyData from './HnEmptyData'
import HnMenu from './HnMenu'
import HnSyncLoading from './HnSyncLoading'
import HnDateSearch from './HnDateSearch'
import HnSplitPane from './HnSplitPane'
import HnRemarks from './HnRemarks'
import HnFormatDic from './HnFormatDic'
import showDialog from './HnDialog'
 
const components = [
    HnContent,        //   内容页面布局组件
    HnEmptyData,      //   空数据暂位
    HnMenu,           //  多级菜单
    HnSyncLoading,    //  动画进度条
    HnDateSearch,    //  日期预设选择
    HnSplitPane,    //  侧边抽屉
    HnRemarks,      //  弹框备注
    HnFormatDic,    // 字典
]
const install = function (Vue, opts = {}) {
    /* istanbul ignore if */
    if (install.installed) return;
    components.map(component => {
        Vue.component(component.name, component);
    });
      /**
    * @param {*} vnode 组件虚拟dome
    * @param {*} slotOption 插入组件配置项
    * @param {*} dialogOption 弹窗组件配置项
    * @param {*} callBack 回调函数
    */
    Vue.prototype.showDialog = function (vnode, slotOption = {}, dialogOption = {}, callback = function () { }) {
        let vnodes = this.$createElement(vnode, slotOption);
        // 将showDialog的属性继承给this，并执行showDoalog
        showDialog.call(this, vnodes, dialogOption, callback);
    }
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}
export default {
    version: '1.0.0',
    install,
    ...components
};