import fetch from '@/libs/fetch'
// 城市查询
export const QweatherLookup = (data) => fetch( 'https://geoapi.qweather.com/v2/city/lookup', {}, Object.assign({},data,{key: '5c857af8c1c4475b94ef5c28a3f81213'}), 'get')
// 城市实时天气查询
export const QweatherNow = (data) => fetch( 'https://devapi.qweather.com/v7/weather/now', {}, Object.assign({},data,{key: '5c857af8c1c4475b94ef5c28a3f81213'}), 'get')
// 空气指数
export const QweatherIndices = (data) => fetch( 'https://devapi.qweather.com/v7/indices/1d', {}, Object.assign({},data,{key: '5c857af8c1c4475b94ef5c28a3f81213',type: 10}), 'get')
// 7天天气预报
export const Qweather7d = (data) => fetch( 'https://devapi.qweather.com/v7/weather/7d', {}, Object.assign({},data,{key: '5c857af8c1c4475b94ef5c28a3f81213'}), 'get')
// 灾害预警
export const QweatherWarning = (data) => fetch( 'https://devapi.qweather.com/v7/warning/now', {}, Object.assign({},data,{key: '5c857af8c1c4475b94ef5c28a3f81213'}), 'get')

