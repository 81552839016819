import { isObject,hasOwn } from "@/libs/type.js";
// import { hasOwn } from "@/libs/util.js";
export default {
    computed: {
        isUseRole(){
            return code => {
                if(code){
                   
                    let menu = this.$store.state.user.menuList 
                    let routePath = this.$route.path 
                    // 当路由相等 并且权限通过时 设置为视频可控
                    const result = menu.some(item => item.path == routePath && item.funcs.includes(code))
                    return result 
                }
                return false
            }
                                    
        },
        $dicColor () {
      
            return this.$store ?.state.user.dicColor  // 字典颜色对象
        },
        $companyId () {
            return this.$store.state.user ?.userInfo.companyId
        }
    },
    methods: {
        mapTo(from, to) {
            if (isObject(from) && isObject(to)) {
                Object.keys(to).forEach(key => {
                    if (hasOwn(from, key)) {
                        to[key] = from[key];
                    }
                })
            }
        },
    }
}