<template>
  <div class="app-format-dic">
    <span :class="{'pointer':pointer}" v-for="(item, idx) in list" :key="idx" :style="item.style" style="margin:0 3px">{{item.text}}</span>
  </div>
</template>

<script>
const defaultColor = "#fff";
export default {
  name: "HnFormatDic",
  props: {
    value: [Number, String],
    type: {
      type: String,
      required: true,
    },
    errorText: String,
    exclude: [Array, Function],
    flags: {
      type: Boolean,
      default: false,
    },
    color: {
      type: [Object,String],
      default: defaultColor
    },
    pointer: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      newValue: null,
    };
  },
  watch: {
    value: {
      handler (val) {
        if (val !== 0 && !val) {
          val = null;
        } else {
          val = parseInt(val);
        }
        if (this.flags) {
          let arr = [];
          if (val != null) {
            for (var i = 0; i < 32; i++) {
              let v = 0x01 << i;
              if (val & v) arr.push(v);
            }
          }
          this.newValue = arr;
        } else {
          this.newValue = val;
        }
      },
      immediate: true,
    },
  },
  computed: {
    
    excludeFun () {
      if (!this.exclude) {
        return function (val) {
          return false;
        };
      }
      if (typeof this.exclude == "function") {
        return this.exclude;
      }
      return function (val) {
        return this.exclude.indexOf(val) >= 0;
      };
    },
    options () {
      if (!this.type) return [];
      let opts = this.$dictionary(this.type).filter(
        (d) => this.excludeFun(d.value) !== true
      );
      return opts;
    },
    colors () {
      let colorInfo = this.$dicColor ?.[this.type]
      let colors = {};
      if(colorInfo){
         colors = colorInfo
      }else{
        if(typeof this.color == 'string'){
           this.options.forEach(o => {
            colors[o.value] = this.color;
           }) 
        }else{
          colors = this.color
        }
         
      }
      return colors;
    },
    list () {
      if (this.flags) {
        return this.newValue.map((val, index) => {
          let item = this.options.find(a => a.value == val) || { label: this.errorText || val };
          return {
            text: item.label,
            style: {
              color: this.colors[val] || defaultColor
            }
          }
        });
      }
      let item = this.options.find(a => a.value == this.newValue) || { label: this.errorText || this.newValue };
      return [
        {
          text: item.label,
          style: {
            color: this.colors[this.newValue] || defaultColor
          }
        }
      ]
    }
  }
};
</script>
<style lang="scss" scoped>
.app-format-dic {
  .pointer:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>