export default {
  computed: {
 
    wsUrl(){
      return  this.$store.state.user.userInfo ?.wsUri || ''
    },
  },
  methods: {
    // websocket建立链接
    global_websocket_connect() {
      this.$store.dispatch('WEBSOCKET_INIT', this.wsUrl) // 建立链接
    },
  
    // 接收订阅消息
    global_websocket_receive_message(devCode,callback,isCtl= true) {
      const stompClient = this.$store.getters.stompClient
       const topic = isCtl ? "/topic/executordata." + devCode : devCode
      const interval = setInterval(() => { // 增改轮询解决刷新问题
        if (stompClient != null && stompClient.connected) {
          clearInterval(interval)
          let topicInfo = stompClient.subscribe(topic, res => {
         
            // console.log('接收消息', JSON.parse(res.body))
            callback(res)
            
          },{id:topic})
          this.$store.commit('addListenerList',{topic,callback})
        } else {
          console.log('等待连接中')
        }
      }, 1000)
    },
    // 取消订阅消息
    global_websocket_unsubscribe(devCode) {
      const stompClient = this.$store.getters.stompClient
      if (stompClient != null && stompClient.connected) {
        this.$store.dispatch('WEBSOCKET_UNSUBSRCIBE', "/topic/executordata." + devCode)
      }
    },

  }
}