<template>
  <div class="hn_main_box pageBg">
    <div class="hn_screen_container_box" ref="fullBoxs">
      <div class="hn_top_box boxBg">
        <div class="left_box">
          <img :src="companyLogo"></img>
          <span>{{ companyName }}</span>
        </div>
        <div class="center_box">
          <ul class="nav_box">
            <li :class="['subIten_box','cp', navActive === item.path ? 'nav-active':'']" @click="navChange(item)" v-for="item of navList" :key="item.path">
              <!-- <span :class="item.icon"></span> -->
              <svg class="font-icon font-icon-svg" aria-hidden="true">
                <use :xlink:href="iconHanlde(item.icon)"></use>
              </svg>
              <span class="sinleline tc">{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div class="right_box">
          <template v-show="farmSiteUrl">
            <el-button type="text" class="ctr-but cp" @click="goManager">控制台</el-button>
            <span class="line_box"></span>
          </template>

          <!-- <el-badge :value="3" class="message-badge">
              <span class="message_box el-icon-bell"></span>
            </el-badge> -->
          <!-- 用户头像 -->
          <div class="user-avator">
            <el-avatar size="small" :src="userPhoto">
              <img :src="defaultuserPhoto" />
            </el-avatar>

          </div>
          <!-- 用户名下拉菜单 -->
          <el-dropdown class="user-name" trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userName }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item divided command="userInfo">个人中心</el-dropdown-item> -->
              <!-- <el-dropdown-item divided command="editPass">修改密码</el-dropdown-item> -->
              <!-- <el-dropdown-item command="helpcontent">帮助中心</el-dropdown-item> -->
              <!-- <el-dropdown-item command="loginout" icon="iconfont icon-dizhiguanli">地图切换</el-dropdown-item> -->
              <el-dropdown-item command="loginout" icon="iconfont icon-zhuanru">退出登录</el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="hn_content_box pageBg">
        <router-view />
      </div>
      <div class="hn_footer_box">
        <span>{{ copy }}</span>
        <span>{{ icpCode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { colourIocnName, imgAddHost } from '@/libs/util'
import mgr from "@/libs/oidc.js";
export default {
  name: 'Home',
  data () {
    return {
      navActive: '',
      name: '用户名'
    }
  },
  created () {
    this.navActive = this.$route.fullPath
    //  this.reqIotUserMenuItem()
    this.routeAttach(this.$route.path)
  },
  watch: {
    $route (to) {
      this.routeAttach(to.path)
    }
  },
  mounted () {
    this.onResize()
  },
  computed: {
    iconHanlde () {
      return icon => {
        return colourIocnName(icon)
      }
    },
    userName () {

      let { nickname, realname, username } = this.$store.state.user.userInfo
      if (nickname) {
        return nickname
      } else if (nickname == '' && nickname == null) {
        return realname
      } else if (realname == '' && realname == null) {
        return username
      } else if (username == '' && username == null) {
        return this.name
      }

    },
    defaultuserPhoto () {
      let { sex } = this.$store.state.user.userInfo
      let icon = sex && sex == 1 ?
        require('../assets/imgs/head/girlIcon.png')
        : require('../assets/imgs/head/boyIcon.png')
      return icon
    },
    userPhoto () {
         let userInfo = this.$store.state.user.userInfo;
         let photo = userInfo ? userInfo.headUrl ? userInfo.headUrl : userInfo.wxHeadUrl  : ''
         return photo ? imgAddHost(photo) : this.defaultuserPhoto;
    },
    companyName () {
      let cInfo = this.$store.state.user.companyInfo
      if (cInfo) {
        return cInfo.title
      }
      return '物联网综合管理平台'
    },
    companyLogo () {
      let cInfo = this.$store.state.user.companyInfo
      if (cInfo && cInfo.miniLogo && config.IpUrl) {
        return (config.IpUrl + cInfo.miniLogo)
      } else {
        return require('../assets/imgs/index/indexDefault/220.png')
      }
    },
    copy () {
      let cInfo = this.$store.state.user.companyInfo
      if (cInfo) {
        return cInfo.copyright || ''
      }
    },
    icpCode () {
      let cInfo = this.$store.state.user.companyInfo
      if (cInfo) {
        return cInfo.icpCode || ''
      }
    },
    farmSiteUrl () {
      let cInfo = this.$store.state.user.companyInfo
      if (cInfo) {
        return cInfo.farmSiteUrl || ''
      }

    },
    navList () {
      return this.$store.state.user.menuList
    }
  },
  methods: {
    goManager () {
      let url = this.farmSiteUrl
      if (url) location.href = url

    },
    onResize () {
      this.$nextTick(() => {
        const currentWidth = document.body.clientWidth
        this.$refs.fullBoxs.style.transform = `scale(${currentWidth / 1920})`
        window.addEventListener('resize', this.onResize)
      })
    },
    navChange (item) {
      let { type, path } = item
     
      if (type == 2 && path.indexOf('http') > -1) {
        window.open(path)
      } else {
        this.$router.push({ path })
         this.navActive = path
      }

    },
    //   async reqIotUserMenuItem(){
    //        const res = await this.$api.IotUserMenuItem()

    //        if(res && res.length){
    //          if(res[0].children.length){
    //            this.navList = res[0].children[0].children || []
    //          } 
    //        }
    //   },
    routeAttach (path) {
      const attachDataList = {
        '/historyInsect': '/Insect'
      }
      Object.keys(attachDataList).map(key => {
        if (path === key) {
          this.navActive = attachDataList[key]
          return
        }
      })
    },
    handleCommand (command) {
      switch (command) {
        case "loginout":
          this.$confirm("是否确定退出登录", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              localStorage.clear();
              mgr().signoutRedirect();

            })
            .catch(() => { });
          break;
        // case "helpcontent":
        //   this.$router.push("/helpcontent")
        //   break
      }

    }
  }
}
</script>
<style lang="scss" scoped>
// flex布局 默认居中
@mixin flex($center1: center, $center2: center) {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: $center1; // flex-start | flex-end | center | space-between | space-around
  align-items: $center2; // flex-start | flex-end | center | baseline | stretch
}
.hn_main_box {
  width: 100vw;
  height: 100vh;
  .hn_screen_container_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 1920px;
    height: 937px;
    overflow: hidden;
    transform-origin: left top;
    .hn_top_box {
      width: 100%;
      height: 80px;
      @include flex(center, center);
      .left_box {
        //  width: 200px;
        height: 100%;
        font-size: 26px;
        color: #fff;
        padding: 0 120px 0 20px;
        background: url('../assets/imgs/index/indexDefault/top_left.png') no-repeat bottom right ,linear-gradient(to right, #1B2E3C 0%,#1B2E3C 50%,#28353F 50%,#28353F 100%);
        background-size: auto 100%; 
        @include flex(center, center);
        & > span {
          display: block;
        }
        & > img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }
      .center_box {
        flex: 1;
        height: 100%;
        .nav_box {
          width: 100%;
          height: 100%;
          display: flex;
          & > li {
            width: 90px;
            height: 100%;
            @include flex(center, center);
            flex-direction: column;
            & > span {
              display: inline-block;
            }
            & > span:first-child {
              width: 24px;
              height: 24px;
              font-size: 24px;
              // background: #00c395;
            }
            & > span:last-child {
              color: #fff;
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }
      }
      .right_box {
        //  min-width: 200px;
        height: 100%;
        margin-right: 15px;
        @include flex(flex-start, center);
        .ctr-but {
          color: #fff;
          font-size: 16px;
        }
        .line_box {
          display: block;
          width: 1px;
          height: 30px;
          background: #26292e;
          position: relative;
          margin: 0 20px;
          &::after {
            display: block;
            width: 1px;
            height: 30px;
            background: #454b56;
            position: absolute;
            left: 2px;
            top: 0;
            content: "";
          }
        }
        .message-badge {
          & ::v-deep .el-badge__content {
            border: none;
            height: 16px;
            line-height: 15px;
            padding: 0 5px;
          }
          & ::v-deep .el-badge__content.is-fixed {
            top: 3px;
          }
        }

        .message_box {
          display: block;
          font-size: 24px;
          color: #fff;
        }
        .user-name {
          margin-left: 10px;
        }
        .user-avator {
          margin-left: 10px;
        }
        // .user-avator img {
        //   display: block;
        //   width: 40px;
        //   height: 40px;
        //   border-radius: 50%;
        // }
        .el-dropdown-link {
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .hn_content_box {
      width: 100%;
      height: calc(100% - 116px);
    }
    .hn_footer_box {
      width: 100%;
      height: 32px;
      line-height: 36px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      & > span:last-child {
        margin-left: 10px;
      }
    }
  }
  .font-icon-svg {
    width: 28px;
    height: 28px;
    fill: currentColor;
    overflow: hidden;
  }
}
</style>
