import axios from 'axios'
const request = axios.create()
// 获取字典状态颜色
export const getDicColor = () =>{
  return new Promise((resolve,reject) => {
      
      request({ 
        url: 'https://api.nywlw.cn/resource_static/dicColor.json', 
        method:'get', 
        notheaderCustomInfo: true
      })
      .then(res => {
         const data = res ?.data
         if(data) resolve(data)
      })
      .catch(err => {
         reject(err)
      })
      
  })
  
} 