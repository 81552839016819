import Vue from 'vue';
import DialogUI from './dialog.vue';
const DialogConstructor = Vue.extend(DialogUI);  // 将组件添加进vue构造器

// 检测key是否在对象上
function hasOwn(obj, name) {
    return Object.prototype.hasOwnProperty.call(obj, name);
}

function isFunction(val) {
    return typeof (val) === "function";
}
function isObject(val) {
    return typeof val === 'object'
}
function show(vnode, title, opts) {
    // vue构造器构造一个组件实列, 也可以通过.$mount('el')来完成实列创建 
    let instance = new DialogConstructor({
        el: document.createElement('div'),
        router: this.$router,  // 通过call改变this指向，现在this指向vue
        store: this.$store
    });
    // instance组件实列对象属性 直接作用与元素属性上
    instance.title = title;
    // 将vnode 加入插槽
    // 默认插槽使用default ，具名插槽直接将名称设为属性  instance.$slots.test = [vnode];
    instance.$slots.default = [vnode];

    // 将数据量挂载到vue实列上
    for (let prop in opts) {
        if (hasOwn(opts, prop)) {
            instance[prop] = opts[prop];
        }
    }

    let app = document.body;
    // 先保存初始close方法，再将新方法异常组件dome，并调用旧方法
    let oldClose = instance.onClose;
    instance.onClose = () => {
        app.removeChild(instance.$el);
        oldClose.call(instance);
    }

    app.appendChild(instance.$el);
    Vue.nextTick(() => {
        let vm = vnode.componentInstance;
        if (vm) {
            vm.showLoading = function (text = "") {
                instance.loading = true;
                instance.loadingText = text;
            }
            vm.hideLoading = function () {
                instance.loading = false;
                instance.loadingText = "";
            }
        }
        // 触发 open()事件,监听‘dialogVisible’状态改变，触发onOpen()事件
        instance.open();
    });
}

export default function (vnode,dialogOption, callback = function () { }) {
    // 在vnode.data中挂载有全部组件信息
    if (!vnode || !vnode.hasOwnProperty('componentOptions')) {
        throw new Error("参数vnode只能是VNode类型");
    }
    
    if (isFunction(dialogOption)) {
        callback = dialogOption;
        dialogOption= {
            width: '50%'
        }
    }
   
    let width = dialogOption.width || '50%'
    let title = dialogOption.title || '标题'
    let dialogInfo = hasOwn(dialogOption, 'dialog') && isObject(dialogOption.dialog)? dialogOption.dialog: false

    let cb = callback || function () { };
    if (!isFunction(cb)) {
        throw new Error("参数callback只能是Function类型");
    }
    let _this = this;
    let dialog = null;
    let vm = null;
    // opts 会被赋值给 DialogUI组件
    let opts = {
        maskClosable: false,
        width: width,
        fullscreen: false,
        // 最后在DialogUi组件中被调用，所以onOpen内的方法 this指向DialogUi组件 及 instance实列
        onOpen() {
            let instance = this;
            // dialog this指向变量instance，所以其中方法属性指向DialogUi组件
            // 因此通过call()方法传入dialog，才能在vnode组件中调用DialogUi组件属性
            dialog = {
                close() {
                    instance.close();
                },
                ok() {
                    instance.ok();
                },
                callback() {
                    cb.apply(_this, arguments);
                },
                showLoading(text = "") {
                    instance.loading = true;
                    instance.loadingText = text;
                },
                hideLoading() {
                    instance.loading = false;
                    instance.loadingText = "";
                },
                get title() {
                    return instance.title;
                },
                set title(value) {
                    instance.title = value;
                },
                get okLoading() {
                    return instance.confirmButtonLoading;
                },
                set okLoading(value) {
                    instance.confirmButtonLoading = value;
                },
                get okDisabled() {
                    return instance.confirmButtonDisabled;
                },
                set okDisabled(value) {
                    instance.confirmButtonDisabled = value;
                },
                get okShow() {
                    return instance.showConfirmButton;
                },
                set okShow(value) {
                    instance.showConfirmButton = value;
                },
                get okText() {
                    return instance.confirmButtonText;
                },
                set okText(value) {
                    instance.confirmButtonText = value;
                },
                get cancelShow() {
                    return instance.showCloseButton;
                },
                set cancelShow(value) {
                    instance.showCloseButton = value;
                },
                get cancelText() {
                    return instance.cancelButtonText;
                },
                set cancelText(value) {
                    instance.cancelButtonText = value;
                },
                get maskClosable() {
                    return instance.maskClosable;
                },
                set maskClosable(value) {
                    instance.maskClosable = value;
                }
            }
            instance.confirmButtonLoading = false;
            vm = vnode.componentInstance;
            if (vm) {

                vm.dialog = dialog;
                if (isFunction(vm.$options.opened)) vm.$options.opened.call(vm, dialog, cb);

            }
        },
        onBeforeClose() {
            if (vm) {
                if (isFunction(vm.$options.beforeClose)) vm.$options.beforeClose.call(vm, dialog, cb);
                // else if (isFunction(vm.submit)) vm.submit(dialog, cb);
            }
            return false;
        },
        onClose() {
            // 关闭时清空页面加载动画 和按钮加载动画
            dialog.okLoading = false;
            dialog.loading = false;
            //当 实列加载完成 可通过VM.$options设置或获取配置属性和方法
            if (vm) {
                // 当 closed为函数时，调用closed,并注销组件
                if (isFunction(vm.$options.closed)) vm.$options.closed.call(vm, dialog, cb);
                vm.$destroy();
            }
        },
        onOk() {
            if (vm) {
                if (isFunction(vm.$options.submit)) vm.$options.submit.call(vm, dialog, cb);
                else if (isFunction(vm.submit)) vm.submit(dialog, cb);
            }
        }
    };
    if (dialogInfo) {
        
        ["title", "width", "fullscreen","isCenter", "confirmButtonLoading", "confirmButtonDisabled", "showConfirmButton", "showCloseButton", "confirmButtonText", "cancelButtonText", "maskClosable",'isDialogDrag','isBut']
            .forEach(key => {
                // 遍历上述属性是否存在vnode节点上，存在就将值添加进opts配置项
                if (hasOwn(dialogInfo, key)) {
                    opts[key] = dialogInfo[key];
                }
            })
       
    }
    
    // this继承调用show方法 ,此时this指向vue实列
    show.call(this, vnode, title, opts);
    return dialog;
}