<template>
   <div class="wh100 empty_main_box" :style="publicStyle">
        <div :class="['empty-content',isType?'empty_row_box':'empty_col_box']" >
            <img :src="noDataUrl" alt="">
            <p style="color: #fff;">{{ text }}</p>
        </div> 
        <div class="mt-20" v-if="$slots">
            <slot></slot>
        </div>
   </div> 
    

</template>

<script>
    export default {
        name: 'HnEmptyData',
        props:{
           text:{
             type: String,
             default: '暂无数据'
           },
           setType: {
               type: String,
               default: 'row' // col
           },
           size: {
                type: String,
                default: 'medium' // small mini 自定义 eg：150px
           }
        },
        data(){

            return{
                noDataUrl: require('@/assets/imgs/noData/no_data2.png')
            }
        },
        computed:{
           publicStyle(){
               
               const defaultSize = {
                   medium: '130px',
                   small: '80px',
                   mini: '40px'
               }
               const size = this.size
               let result = { '--width': defaultSize.medium }
               if(size){
                  if(defaultSize.hasOwnProperty(size)){
                        result = { '--width': defaultSize[size] }
                  } else if(size.indexOf('px') !== -1){
                        result = { '--width': size }
                  }
               }
               return result
           },
           isType(){
              return this.setType == 'col'? false : true
           } 
        }
    }
</script>

<style lang="scss" scoped>
.empty_main_box{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   font-size: 14px;
   .empty-content{
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
            width: var(--width);
        }
   }
   
}
.empty_row_box{
    & > img {
        margin-right: 25px;
    }
}
.empty_col_box{
   flex-direction: column;
    & > img {
        margin-bottom: 15px;
    }
}
</style>