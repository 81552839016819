import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Swiper from "swiper/swiper-bundle.min.js"
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/swiper-bundle.min.css';
import anime from 'animejs'
// import 'qweather-icons/font/qweather-icons.css'
import flv from 'flv.js'
import api from '@/api'
import './assets/iconfont/iconfont'
import './assets/styles/main.scss'
import { newMessage } from './libs/message'
import globalComponent from '@/components/globalComponent'
import globalMixins from "@/mixins/global.js"
import websocket from '@/mixins/websocket.js'
import scroll from 'vue-seamless-scroll'
import dayjs from 'dayjs'
import dialogDrag from './directive/dialogDrag'

import {
  dictionary,
  formatter,
  company
} from './libs/dictionary'
// dayjs().format()

Vue.config.productionTip = false
Vue.use(scroll)
Vue.use(ElementUI)
Vue.use( globalComponent );
Vue.mixin(globalMixins);
Vue.mixin(websocket);
Vue.directive('dialogDrag', dialogDrag)
Vue.prototype.$flv = flv
Vue.prototype.$api = api
Vue.prototype.$Swiper = Swiper
Vue.prototype.$echarts = echarts
Vue.prototype.$anime = anime
Vue.prototype.$dictionary = dictionary //字典，选择使用
Vue.prototype.$formatter = formatter //字典，列表使用
Vue.prototype.$company = company
Vue.prototype.$msg = newMessage
Vue.prototype.$day = dayjs
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
