<template>
    <div class="loadingModal" :style="{'height':fatherHeight+'px'}" v-if="progressLoading">
      <template v-if="dataStatus !== 'exception'">
         <el-progress
            :style="{width:`${width}%`,margin:'0 auto',marginTop:`${fatherHeight*0.4}px`}"
            :type="types"
            :width="70"
            :text-inside="false"
            :stroke-width="types?undefined:strokeWidth"
            :percentage="percentage"
            :color="colors"
            :show-text="showText"
            :status="dataStatus" 
        ></el-progress>
      </template>
      <template v-else>
         <el-progress 
           :style="{width:`${width}%`,margin:'0 auto',marginTop:`${fatherHeight*0.4}px`}"
           :stroke-width="types?undefined:strokeWidth"
           :type="types"
           :width="70"
           :percentage="50" 
           status="exception"></el-progress>
      </template>
       
    </div>
</template>
<script>

export default {
  name: 'HnSyncLoading',
  data() {
    return {
      percentage: 0,
      timeStart: 0,
      fatherHeight: 0, // 父级元素高度
      colors: '#00C395',
      progressLoading: false,
    };
  },
  props: {
    erroStatus:{
        type: Boolean,
        default: false
    },
    strokeWidth: {
      type: Number,
      default: () => {
        return  24;
      },
    },
    showText: {
      type: Boolean,
      default: true,
    },
    // 进度条状态
    types: {
      type: String,
      default: () => {
        return undefined;
      },
    },
    // 进度
    speedTime: {
        type: Number,
        default: 500
    },
    // 显示状态
    loading: {
      type: Boolean,
      default: false,
    },
    width:{
       type: String,
       default: '20'
    }
  },
  mounted() {
    // 获取父级元素的高度，使组件高度与父元素高度相同
    // 相对于父元素定位
    this.$el.parentNode.style.position = "relative";
  },
  computed: {
    dataStatus(){
     
      let data=  !this.erroStatus ? this.percentage === 100?'success': undefined : 'exception'
    
      return data
    }
  },
  methods: {
    // 进度条开始
    start() {
      this.$nextTick(() => {
          const that = this;
          that.fatherHeight = this.$el.parentNode.offsetHeight;
          that.$nextTick(() => {
            that.progressLoading = true;
            that.percentage = 0;
            that.timeStart = setInterval(() => {
              if (that.percentage < 95) {
                that.percentage += 5;
              }else{
                  this.$emit('onEnd')
                  clearInterval(this.timeStart);
              }
            }, this.speedTime);
          });
      })
     
    },
    // 进度条结束
    end(reqErro = false) {
      
      const that = this;

      if(!reqErro) that.percentage = 100;
       
      clearInterval(this.timeStart);
      setTimeout(() => {
        that.progressLoading = false;
        
        if(!reqErro){
          this.$msg.success('保存成功')
        }
        
      }, 1000);
    },
  },
  watch: {
    // 监听loading状态控制进度条显示
    loading:{
        handler(val,oldVal){
          if(oldVal == undefined && !val) return
          val ? this.start() : this.end()
        },
        immediate: true
    },
    // 监听请求错误
    erroStatus(value) {
       const that = this
       if(value) that.end(true); 
    }
  },
};
</script>
<style lang="scss" scoped>
.loadingModal {
  height: var(height);
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
 
}
</style>