<template>
  <transition name="fade">
  <div ref="splitPane" v-if="splitChange" class="split-pane" :class="direction" :style="{ flexDirection: direction }">
    <div class="pane pane-one" :style="lengthType + ':' + paneLengthValue">
      <!-- <slot name="one"></slot> -->
    </div>
    
    <div 
      class="pane-trigger boxBg" 
      :style="lengthType + ':' + triggerLengthValue" 
      @mousedown="handleMouseDown"
    >
      <span class="active"></span>
    </div>
    
    <div class="pane pane-two">
      <slot></slot>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: 'HnSplitPane',
  props: {
    direction: {
      type: String,
      default: 'row'
    },
  
    min: {
      type: Number,
      default: 10
    },

    max: {
      type: Number,
      default: 90
    },

    paneLengthPercent: {
      type: Number,
      default: 50
    },

    triggerLength: {
      type: Number,
      default: 10
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      triggerLeftOffset: 0, // 鼠标距滑动器左(顶)侧偏移量
      splitChange: false
    }
  },
  watch:{
     value:{
       handler(val){
    
           this.splitChange = val
       }
     }
  },
  created(){

     this.splitChange = this.value
  },
  computed: {
    lengthType() {
      return this.direction === 'row' ? 'width' : 'height'
    },

    paneLengthValue() {
      return `calc(${this.paneLengthPercent}% - ${this.triggerLength / 2 + 'px'})`
    },

    triggerLengthValue() {
      return this.triggerLength + 'px'
    }
  },

  methods: {
    // 按下滑动器
    handleMouseDown(e) {
      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUp)

      if (this.direction === 'row') {
        this.triggerLeftOffset = e.pageX - e.srcElement.getBoundingClientRect().left
      } else {
        this.triggerLeftOffset = e.pageY - e.srcElement.getBoundingClientRect().top
      }
    },
    // 关闭
    colseSplit(){
        this.splitChange = false
        this.$emit('input',false)
    },
    // 按下滑动器后移动鼠标
    handleMouseMove(e) {
      const clientRect = this.$refs.splitPane.getBoundingClientRect()
      let paneLengthPercent = 0

      if (this.direction === 'row') {
        const offset = e.pageX - clientRect.left - this.triggerLeftOffset + this.triggerLength / 2
        paneLengthPercent = (offset / clientRect.width) * 100
      } else {
        const offset = e.pageY - clientRect.top - this.triggerLeftOffset + this.triggerLength / 2
        paneLengthPercent = (offset / clientRect.height) * 100
      }

      if (paneLengthPercent < this.min) {
        paneLengthPercent = this.min
      }
      if (paneLengthPercent > this.max) {
        paneLengthPercent = this.max
      }

      this.$emit('update:paneLengthPercent', paneLengthPercent)
    },

    // 松开滑动器
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMouseMove)
    }
  }
}
</script>

<style scoped lang="scss">
.split-pane {
//   background: palegreen;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10001;
  display: flex;
  &.row {
    .pane {
      height: 100%;
    }
    .pane-trigger {
      height: 100%;
      cursor: col-resize;
      display: flex;
      justify-content: center;
      align-items: center;
      & > span {
        width: 3px;
        height: 30px;
      }
    }
  }
  &.column {
    .pane {
      width: 100%;
    }
    .pane-trigger {
      width: 100%;
      cursor: row-resize;
    }
  }
  .pane-one {
    // background: palevioletred;
  }
  .pane-trigger {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid #344552;
    // border-top: 2px solid #344552;
    // border-bottom: 2px solid #344552;

    // & > span{
    //    color: #fff;
    //    font-size: 14px;
    // }
  }
  .pane-two {
    flex: 1;
    background:rgba(#15232D,0.9);
    box-sizing: border-box;
    box-shadow: -4px 0px 5px -2px rgba(46, 45, 45, 0.75);
    // border-top: 1px solid #344552;
    // border-bottom: 1px solid #344552;

  }
}
</style>
