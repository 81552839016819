import { FormItem } from "element-ui"

const baseUrl = process.env.VUE_APP_API_URL
const sysCode = process.env.VUE_APP_CODE || ''
export default {
    /**
     * @description api请求基础路径
     */
    baseUrl: {
        dev: '/api',
        pro: baseUrl,
    },
    IpUrl: baseUrl,
    sysSetting: {
        name: '耘呱呱',
        proId: '10001'
    },
    defaultToken: '0eb9bc69fce340129d6b2ebe70e045da',
    /**
     * @description 控制密码有效期 默认5分钟
     */
    ctlPwdTermTime:  300 * 1000, 
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: '耘呱呱',
    /**
   * @description 默认打开的首页的路由name值
   */
    homeName: '_home',
    code: sysCode,
    devStatus:{
        online: 9,
    },
    /**
   * @description 通信图标配置
   */
   netModel:{
       '1':{
           title:'GPRS',
           icon: 'icon-GPRS'
       },
       '2':{
            title:'以太网',
            icon: 'icon-yitaiwang'
        },
        '3':{
            title:'NB-Iot',
            icon: 'icon-NB'
        },
        '4':{
            title:'wifi',
            icon: 'icon-WIFIxinhao-ji'
        },
        '5':{
            title:'CAT-1',
            icon: 'icon-CAT'
        },
        '6':{
            title:'4G',
            icon: 'icon-Cellular4G-1'
        },
      
   },
    /**
   * @description 天气预警颜色配置
   */
    warnWeatherColor:{
        '蓝色':'#41a0f8',
        '黄色':'#f3e843',
        '橙色':'#ff9000',
        '红色':'#ff5400'
    },
    /**
   * @description 首页地图 设备参数 显示类型
   */
   paramShowType:{
       '1': 'collectionParam', // 实时参数
       '2': 'videoParam',    // 视频直播
       '3': 'imgsParam',     // 视频图片
       '4': 'ctlParam',     // 控制设备
       '5': 'insectParam',   // 虫情分析
       '6': 'insectLamp',   // 杀虫灯类型
   },
    /**
   * @description 菜单设备类型
   */
     menuDevType:{
        '采集设备': '1001', 
        '控制设备': '1002',    // 视频直播
        '采控设备': '1003',   
        '视频设备': '1004',      
        '虫情设备': '1005',    
        '杀虫灯设备': '1006',    
        '灌溉设备': '1007',    
        '孢子捕捉设备': '1011'
    },
    /**
   * @description 视频控制类型
   */
   videoCtlType:{
       'top': 0,
       'bottom': 1,
       'left': 2,
       'right': 3,
       'big': 8,
       'small':9
   },
   /**
   * @description 虫情类型颜色值
   */
  insectColor:[
    "rgba(0, 0, 200, 0.5)",
    "rgba(45, 202, 147, 0.5)",
    "rgba(76, 199, 247, 0.5)",
    "rgba(255, 77, 79, 0.5)",
    "rgba(36, 215, 193, 0.5)",
    "rgba(255, 159, 9, 0.5)",
    "rgba(59, 154, 228, 0.5)",
    "rgba(216, 27, 178, 0.5)",
    "rgba(175, 232, 26, 0.5)",
  ],
   /**
   * @description 虫情设备状态字典
   */
  insectStutasDis:{
    rps:{
        title: '雨控状态',
        statusText: {
            '0': '正常',
            '1': '雨控'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-yukongzhuangtai'
    },
    lps:{
        title: '光控状态',
        statusText: {
            '0': '正常',
            '1': '光控'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-guangkongzhuangtai'
    },
    tps:{
        title: '温控状态',
        statusText: {
            '0': '正常',
            '1': '光控'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-wenkongzhuangtai'
    },
    gs:{
        title: '通道状态',
        statusText: {
            '0': '排水',
            '1': '落虫'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-tongdaozhuangtai'
    },
    upds:{
        title: '上仓门状态',
        statusText: {
            '0': '关闭',
            '1': '打开'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-kaimen'
    },
    dnds:{
        title: '下仓门状态',
        statusText: {
            '0': '关闭',
            '1': '打开'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-kaimen'
    },
    hs:{
        title: '加热状态',
        statusText: {
            '0': '正常',
            '1': '加热'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-jiarezhuangtai'
    },
    ts:{
        title: '定时模式',
        statusText: {
            '0': '光控',
            '1': '时控'
        },
        status: {
            '0': true,
            '1': false
        },
        icon:'icon-dingshizhuangtaisvg'
    },

  }
}