<template>
    <div class="navSearch_main_box wh100">
       
            <div class="search_box pr">
                    <el-autocomplete
                        class="inline-input"
                        v-model="searchVal"
                        clearable
                        ref="cautocomplete"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入设备序列号或名称"
                        :trigger-on-focus="false"
                        @select="handleSearch"
                        @clear="clearSearch"
                    >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                      <template slot-scope="{ item }">
                          <div class="auto_select_box">
                              <span :class="['iconfont', item.iconCls || 'icon-tongdaozhuangtai', 'baseColor','autoIcon']"></span>
                              <div class="info_box">
                                  <p>{{ item.value }}</p>
                                  <p>{{ item.devCode }}</p>
                              </div>
                          </div>
                      </template>
                    </el-autocomplete>
                    <el-tooltip effect="dark" :content="groupContent" placement="top">
                         <span :class="['active-cp', 'grounpIcon', 'iconfont', groupIcon]" @click="groupChange"></span>        
                    </el-tooltip>
                    
            </div>
        <template v-if="navMenus.length">
            <Menu class="menu_box" 
            :groupState='groupState'
            v-bind="$attrs" 
            v-on="$listeners" 
            :activeInfo='activeInfo'
            :menus="navMenus"></Menu>  
       </template>
       <template v-else>
           <div class="mt100">
                <hn-emptyData :text= "emptyText"  size= '160px' setType= 'col'></hn-emptyData>
           </div>
             
       </template>
       
      
    </div>
</template>

<script>
    import Menu from './Menu.vue'
    import config from '@/config'
    import { deepClone } from '@/libs/util.js'
    export default {
        name: 'HnMenu',
        components:{
            Menu,
        },
        props:{
          menus: {
              type: Array,
              default: () => []
          },
          isArea: {
              type: Boolean,
              default: false
          },
          menuDevType: {
              type: Array,
              default: () => []
          },
          isLive: {
              type: Boolean,
              default: false
          },
          actuatorFun:{
              type: Number
          }
        },
        data(){
            return{
               searchVal: '',
               navMenus: [],
               copyMenus: [],
               restaurants: [], // 搜索信息保存值，包含父级信息  
               activeInfo: null, // 激活菜单项 
               groupState: false,
               activeOpenMenu: [],           
            }
        },
        created(){
      
           this.groupState = this.isArea
           this.reqMenu(this.defaultApi)
             .then(res => this.$emit('onReq',res))
    
        },
        watch:{
           isArea(val) {
              this.groupState =  val  
           }
        },
        computed: {
            reqData() {
               let menuType = this.menuDevType
               let result = null
               if(menuType && menuType.length){
                  const menuDevType = config.menuDevType 
                  let arrList = []
                  menuType.map(item => {
                       let data = menuDevType[item]
                       if(data) arrList.push(data)
                  })
                  
                  result = { devTypes: arrList }
                  if(this.isLive) result.isLive = 1
                  if(this.actuatorFun) result.actuatorFun = this.actuatorFun 
               }  
               return result
            },  
            groupContent() {
               return this.groupState ? '基地菜单' : '分组菜单'
            },
            emptyText() {
               return '暂无' + this.groupContent
            },
            groupIcon() {
               return this.groupState ? 'icon-tongwu-jidi1' : 'icon-tongwu-fenzu' 
            },
            defaultApi() {
                return this.groupState ? 'BaseDeviceList' : 'GroupDeviceList'
            },
        
        },
        methods:{
            async reqMenu(api= 'BaseDeviceList') {
             try{
               const res = await this.$api[api](this.reqData)
               if(res){
                   this.navMenus = res
                   this.copyMenus = res  // 用于搜索还原
                   return res
                } 
             }catch{
                 return false
             }  
               
            },
            
            groupChange(){
               let api = this.groupState ? 'GroupDeviceList' : 'BaseDeviceList' 
               this.reqMenu(api)
                 .then(res =>{
                     this.groupState = !this.groupState
                     this.$emit('onReq',res)
                 })
            },
            querySearch(queryString, cb) {
                
                var restaurants = []
                if(this.navMenus.length){
                     this.navMenus.forEach(item => {
                          if(item.devs && item.devs.length){
                            item.devs.forEach(val => {
                             
                                // value 和 Id 为搜索组件绑定值，key不能更改
                                restaurants.push({
                                    parentId: item.id,
                                    devId: val.devId,
                                    value: val.devName,
                                    devCode: val.devCode,
                                    id: val.id,
                                    onlineStatus: val.onlineStatus,
                                    iconCls: val.iconCls
                                })
                            })
                          }
                     })
                }
                this.restaurants = restaurants
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
              
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    // 这里可以通过value 和 id一起判断
                  return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1 ||
                         restaurant.devCode.toLowerCase().indexOf(queryString.toLowerCase()) > -1
                };
            },
            handleSearch(data) {
                
                let arrList = []
                this.navMenus.some(item => {
                    if(item.id == data.parentId){
                        let copyItem = deepClone(item)
                        this.activeInfo = data
                        copyItem.devs =  this.todo(copyItem.devs,data.devId)
                      
                        arrList.push(copyItem)
                        return true
                    } 
                })
                
                this.navMenus =  arrList

            },
            clearSearch() {
                this.$refs.cautocomplete.activated = true
                this.activeInfo = null
                this.navMenus = this.copyMenus
            },
            todo(arr,id) {
                let index = 1,newArr = [], length = arr.length;
                for(var i = 0; i < length; i++){
                    if(arr[i].devId == id){
                        newArr[0] = arr[i]
                    }else{
                        newArr[index++] = arr[i]
                    }
                }
              
                return newArr

            }
        }
    }
</script>

<style lang="scss" scoped>
 .navSearch_main_box{
    .search_box{
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #DCDFE6;
        margin-bottom: 10px;
        .grounpIcon{
            position: absolute;
            right: 0;
            top: 9px;
            font-size: 20px;
        }
    }
    .menu_box{
        height: calc(100% - 50px);
    }
    .el-autocomplete{
        width: 100%;
    }
    .el-autocomplete ::v-deep .el-input__inner{
        background: none;
        border: none;
        color: #fff;
    }
    .mt100{
        margin-top: 100px;
    }
    .inline-input{
        width: 255px;
    }
  
 }
</style>