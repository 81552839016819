
import fetch from '@/libs/fetch'
/**
 * 获取用户信息
 */
 export const GetUserInfo = () => fetch('/Api/Auth/AccountUser/GetUserInfo', {}, {}, 'POST')
 
 /**
 * 获取站点配置信息
 * @param {object} data {code, appType}
 * @ignore {code:机构编号(如果为空将从host解析), appType:应用类型 1:pc 2:app}
 */
export const GetWebSiteCfg = (data) => fetch('/Api/Auth/Home/GetWebSiteCfg', data, {}, 'POST')
/**
 * 获取系统字典
 */
 export const SysDictionaryList = () => fetch('/Api/Auth/Home/SysDictionaryList', {}, {}, 'POST')
 /**
 * 物联网菜单
 */
export const IotUserMenuItem = () => fetch('/Api/Auth/AccountUser/IotUserMenuItem', {}, {}, 'POST')
/**
 * 登录（不带验证码）
 * @param {object} data {userName, password, companyId, userType}
 * @ignore {userName:登录名, password:密码, companyId:机构Id, userType:用户类型（0:机构用户，1:企业用户）}
 */
 export const LoginForNoVerify = (data) => fetch('/Api/Auth/Account/LoginForNoVerify', data, {}, 'POST')
/**
 * 注册企业账号
 * @param {object} data {companyId, regionId, username, password, confirmPwd, name, isPerson}
 * @ignore {companyId:机构Id, regionId:区域ID, username:账号, password:密码, confirmPwd:确认密码, name:名称(企业名称或个人名称), isPerson:是否是个人}
 */
 export const EntRegister = (data) => fetch('/Api/Auth/Account/EntRegister', data, {}, 'POST')
 /**
 * 获取所有区域列表
 */
export const SysRegionList = () => fetch('/Api/Auth/Home/SysRegionList',{}, {}, 'POST')
/**
 * 获取隐私政策内容
 */
 export const GetUserPrivacy = () => fetch('/Api/Auth/Home/GetUserPrivacy',{}, {}, 'POST')

 /**
  * 获取用户协议内容
  */
 export const GetUserProtocol = () => fetch('/Api/Auth/Home/GetUserProtocol',{}, {}, 'POST')
 
 /**
  * 获取免责声明内容
  */
 export const GetUserStatement = () => fetch('/Api/Auth/Home/GetUserStatement',{}, {}, 'POST')
    
    
