import axios from '@/libs/request'

export default (url = '', data = {}, params = {}, method = "post") => {    
   
    if(url && typeof url === 'object'){
        return axios.request(url)
    }else {
        return axios.request({
            url: url,
            data,
            params,
            method: method,
            datatype: 'json'
        })  
    }
    
}