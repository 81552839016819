import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import websocket from './modules/websocket'
import api from '@/api'
Vue.use(Vuex)
let localCtlPageStutas = sessionStorage.getItem('ctlStutas')
const defaultCtlInfo = { pageType: 'devControl',devMenuInfo: '',taskId: '',setType: 1,showTask: true}
localCtlPageStutas = localCtlPageStutas ? JSON.parse(localCtlPageStutas) : defaultCtlInfo
export default new Vuex.Store({
  state: {
    devSensorList: [],
    topicQueueList: [],
    ctlPageStutas: localCtlPageStutas
  },
  getters:{
    stompClient: state => state.websocket.stompClient,
    ctlActivePageCode: state => state.ctlPageStutas.pageType,
    ctlActiveMenuInfo: state => state.ctlPageStutas.devMenuInfo,
    ctlActiveTaskId: state => state.ctlPageStutas.taskId,
    ctlActiveSetCode: state => state.ctlPageStutas.setType,
    ctlShowTask: state => state.ctlPageStutas.showTask
  },
  mutations: {
     setTopicQueue(state,data){
        state.topicQueueList = data
     },
     setCtlPageStutas(state,data){
        Object.assign(state.ctlPageStutas,data)
        sessionStorage.setItem('ctlStutas',JSON.stringify(state.ctlPageStutas))
     }
    
  },
  actions: {
    reqIotDevSensorChooseList({state}){
      api.IotDevSensorChooseList().then(res => {
          state.devSensorList= res || []
      })
    }
  },
  modules: {
    user,
    websocket
  }
})
