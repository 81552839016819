<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import { setTheme } from './assets/theme/setTheme'
  export default {
      created(){
        setTheme()
      }
  }
</script>
<style lang="scss">
 @import './assets/styles/font.css';
 @import './assets/iconfont/iconfont.css';
//  @import 'https://cdn.jsdelivr.net/npm/qweather-icons@1.0.2/font/qweather-icons.css';
@import './assets/qweather/qweather-icons.css';
</style>
