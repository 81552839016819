<template>
    <div class="hnRemarks_box" :style="varStyle">
        <el-popover
        :placement="placement"
        :width="width"
        popper-class='activeBorder_popover'
        :trigger="trigger"
        :content="content">
         <template>
          <slot></slot>
         </template>
          <span class="el-icon-question iconbox cp" slot="reference"></span>
        </el-popover>
         
    </div>
</template>

<script>
    export default {
        name: 'HnRemarks',
        props:{
            color:{   
                type: String,
                default: '#ffa800'
            },
            size:{
               type: String || Number,
               default: '18px' 
            },
            width:{
                type: String,
                default: '200'
            },
            content: {
                type: String,
                default: '请输入内容'
            },
            placement: {
                type: String,
                default: 'top'
            },
            trigger: {
                type: String,
                default: 'hover' 
            }
        },
        computed:{
            varStyle(){
               let size = this.size.includes('px')? this.size : this.size + 'px'
               let color = this.color
               return {
                   '--size': size,
                   '--color': color
               }
            }
        }
    }
</script>

<style lang="scss" scoped>
.hnRemarks_box{
    .iconbox{
        font-size: var(--size);
        color: var(--color);
    }
}
</style>