import fetch from '@/libs/fetch'

/**
 * 
 */
export const UpdateDevVideoUrl = () => fetch('/Api/Test/UpdateDevVideoUrl',{}, {}, 'GET')

/**
 * 
 */
export const GetDSSToken = () => fetch('/Api/Test/GetDSSToken',{}, {}, 'GET')

/**
 * 孢子捕捉仪图片信息
 * @param {object} data {id, sDate, eDate, pageSize, pageIndex, sorts}
 * @ignore {id:设备code或者id, sDate:开始时间, eDate:结束时间, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotSporePhotosPageList = (data) => fetch('/Api/Web/IotSporePhotos/IotSporePhotosPageList', data, {}, 'POST')

/**
 * 添加病虫害防治建议
 * @param {object} data {imgId, content}
 * @ignore {imgId:图片Id, content:意见内容}
 */
export const AddIotInsectImgAdvice = (data) => fetch('/Api/Web/IotInsectImgAdvice/AddIotInsectImgAdvice', data, {}, 'POST')

/**
 * 病虫害防治建议详情
 * @param {object} data {imgId}
 * @ignore {imgId:图片Id}
 */
export const IotInsectImgAdviceDetails = (data) => fetch('/Api/Web/IotInsectImgAdvice/IotInsectImgAdviceDetails', data, {}, 'POST')

/**
 * 图片数据分析
 * @param {object} data {imgId}
 * @ignore {imgId:图片id}
 */
export const IotInsectImgDataAnalysis = (data) => fetch('/Api/Web/IotInsectImgData/IotInsectImgDataAnalysis', data, {}, 'POST')

/**
 * 虫情照片列表("300003")
 * @param {object} data {insectId, sTime, eTime, timeType, pageSize, pageIndex, sorts}
 * @ignore {insectId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotInsectImgsPageList = (data) => fetch('/Api/Web/IotInsectImgs/IotInsectImgsPageList', data, {}, 'POST')

/**
 * 虫情照片列表后端使用("300015","301611")
 * @param {object} data {insectId, sTime, eTime, timeType, pageSize, pageIndex, sorts}
 * @ignore {insectId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotInsectImgsBackPageList = (data) => fetch('/Api/Web/IotInsectImgs/IotInsectImgsBackPageList', data, {}, 'POST')

/**
 * 电子围栏列表（分页）
 * @param {object} data {fenceName, fenceNum, fenceStatus, baseInfoId, pageSize, pageIndex, sorts}
 * @ignore {fenceName:围栏名称，唯一, fenceNum:围栏编号，唯一, fenceStatus: , baseInfoId:所属基地，基地信息ID, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const TraFencePageList = (data) => fetch('/Api/Web/TraFence/TraFencePageList', data, {}, 'POST')

/**
 * 电子围栏列表（不分页）
 * @param {object} data {fenceName, fenceNum, fenceStatus, baseInfoId, sorts}
 * @ignore {fenceName:围栏名称，唯一, fenceNum:围栏编号，唯一, fenceStatus: , baseInfoId:所属基地，基地信息ID, sorts: }
 */
export const TraFenceList = (data) => fetch('/Api/Web/TraFence/TraFenceList', data, {}, 'POST')

/**
 * 获取指定动物轨迹
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const TraFenceAnimalLocus = (data) => fetch('/Api/Web/TraFence/TraFenceAnimalLocus', data, {}, 'POST')

/**
 * 获取当前动物位置
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const TraFenceAnimalPosition = (data) => fetch('/Api/Web/TraFence/TraFenceAnimalPosition', data, {}, 'POST')

/**
 * 动物信息选择列表
 * @param {object} data {enterpriseId, batchNo, breedId}
 * @ignore {enterpriseId:企业Id, batchNo:出栏批次号, breedId:种养品Id}
 */
export const TraAnimalInfoDropList = (data) => fetch('/Api/Web/TraAnimalInfo/TraAnimalInfoDropList', data, {}, 'POST')

/**
 * 动物体温分析
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const TraAnimalInfoTTmpAnalyse = (data) => fetch('/Api/Web/TraAnimalInfo/TraAnimalInfoTTmpAnalyse', data, {}, 'POST')

/**
 * 动物生理分析
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const TraAnimalInfoPhysiology = (data) => fetch('/Api/Web/TraAnimalInfo/TraAnimalInfoPhysiology', data, {}, 'POST')

/**
 * 动物步数分析
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const TraAnimalInfoStepNumber = (data) => fetch('/Api/Web/TraAnimalInfo/TraAnimalInfoStepNumber', data, {}, 'POST')

/**
 * 编辑告警信息已读
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EditTraWarningInfoStatus = (data) => fetch('/Api/Web/TraWarningInfo/EditTraWarningInfoStatus', data, {}, 'POST')

/**
 * 逻辑批量删除告警信息
 * @param {object} data {ids}
 * @ignore {ids:多个Id}
 */
export const DelTraWarningInfo = (data) => fetch('/Api/Web/TraWarningInfo/DelTraWarningInfo', data, {}, 'POST')

/**
 * 告警信息列表（分页）
 * @param {object} data {isView, pageSize, pageIndex, sorts}
 * @ignore {isView:true 只显示未读 false 显示全部, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const TraWarningInfoPageList = (data) => fetch('/Api/Web/TraWarningInfo/TraWarningInfoPageList', data, {}, 'POST')

/**
 * 告警信息列表（不分页）
 * @param {object} data {isView, sorts}
 * @ignore {isView:true 只显示未读 false 显示全部, sorts: }
 */
export const TraWarningInfoList = (data) => fetch('/Api/Web/TraWarningInfo/TraWarningInfoList', data, {}, 'POST')

/**
 * 人员订阅策略明细
 * @param {object} data {isWeb}
 * @ignore {isWeb:物联网前端使用传true 其他不传}
 */
export const ComAdminSubDetail = (data) => fetch('/Api/Web/ComAdminSub/ComAdminSubDetail', data, {}, 'POST')

/**
 * 物联网前端人员订阅策略添加
 * @param {object} data {receiveMode}
 * @ignore {receiveMode:接收方式
1.App
2.短信
4.邮件
8.语音电话}
 */
export const SetWebComAdminSub = (data) => fetch('/Api/Web/ComAdminSub/SetWebComAdminSub', data, {}, 'POST')

/**
 * 新增设备本地执行器定时(重复添加会自动编辑原有数据)
 * @param {object} data {code, channelNo, ctlTarget, cron, ctlStatus, openPercent, delayTime, status, devId, ctlNode, data}
 * @ignore {code:执行器命令编号
数据库存储（0601，0602）展示01，02, channelNo:通道号（执行器编号）, ctlTarget: 受控目标
 受控目标=0：对应节点的 485 通道号
受控目标=1：对应节点的受控通道号
受控目标 = 2：对应节点的执行器编号, cron:时间表达式, ctlStatus:控制状态, openPercent:开启百分比, delayTime:延时时长, status:启用状态, devId:设备Id, ctlNode: 受控节点
节点设备标识（全 00 表示自身，全 FF 表示自身+所有控制节点，全 FE 表示所有控制节点）, data:485数据}
 */
export const AddIotActuatorTimer = (data) => fetch('/Api/Web/IotActuatorTimer/AddIotActuatorTimer', data, {}, 'POST')

/**
 * 编辑设备本地执行器定时
 * @param {object} data {code, channelNo, ctlTarget, cron, ctlStatus, openPercent, delayTime, status, devId, ctlNode, data, id}
 * @ignore {code:执行器命令编号
数据库存储（0601，0602）展示01，02, channelNo:通道号（执行器编号）, ctlTarget: 受控目标
 受控目标=0：对应节点的 485 通道号
受控目标=1：对应节点的受控通道号
受控目标 = 2：对应节点的执行器编号, cron:时间表达式, ctlStatus:控制状态, openPercent:开启百分比, delayTime:延时时长, status:启用状态, devId:设备Id, ctlNode: 受控节点
节点设备标识（全 00 表示自身，全 FF 表示自身+所有控制节点，全 FE 表示所有控制节点）, data:485数据, id:Id}
 */
export const EditIotActuatorTimer = (data) => fetch('/Api/Web/IotActuatorTimer/EditIotActuatorTimer', data, {}, 'POST')

/**
 * 删除设备本地执行器定时
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotActuatorTimer = (data) => fetch('/Api/Web/IotActuatorTimer/DelIotActuatorTimer', data, {}, 'POST')

/**
 * 设备本地执行器定时列表（传设备id）
 * @param {object} data {devId}
 * @ignore {devId:设备id}
 */
export const IotActuatorTimerList = (data) => fetch('/Api/Web/IotActuatorTimer/IotActuatorTimerList', data, {}, 'POST')

/**
 * 修改设备本地执行器定时状态
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EidtIotActuatorTimerStatus = (data) => fetch('/Api/Web/IotActuatorTimer/EidtIotActuatorTimerStatus', data, {}, 'POST')

/**
 * 新增设备本地执行器联动(重复添加会自动编辑原有数据)
 * @param {object} data {code, channelNo, ctlTarget, ctlStatus, openPercent, delayTime, status, devId, ctlNode, data, addIotActuatorLinkageConditionDtos}
 * @ignore {code:执行器命令编号
数据库存储（0601，0602）展示01，02, channelNo:通道号（执行器编号）, ctlTarget: , ctlStatus:控制状态, openPercent:开启百分比, delayTime:延时时长, status:启用状态, devId:设备Code, ctlNode: 受控节点
节点设备标识（全 00 表示自身，全 FF 表示自身+所有控制节点，全 FE 表示所有控制节点）, data:485数据, addIotActuatorLinkageConditionDtos:联动条件}
 */
export const AddIotActuatorLinkage = (data) => fetch('/Api/Web/IotActuatorLinkage/AddIotActuatorLinkage', data, {}, 'POST')

/**
 * 编辑设备本地执行器联动
 * @param {object} data {code, channelNo, ctlTarget, ctlStatus, openPercent, delayTime, status, devId, ctlNode, data, addIotActuatorLinkageConditionDtos, id}
 * @ignore {code:执行器命令编号
数据库存储（0601，0602）展示01，02, channelNo:通道号（执行器编号）, ctlTarget: , ctlStatus:控制状态, openPercent:开启百分比, delayTime:延时时长, status:启用状态, devId:设备Code, ctlNode: 受控节点
节点设备标识（全 00 表示自身，全 FF 表示自身+所有控制节点，全 FE 表示所有控制节点）, data:485数据, addIotActuatorLinkageConditionDtos:联动条件, id:Id}
 */
export const EditIotActuatorLinkage = (data) => fetch('/Api/Web/IotActuatorLinkage/EditIotActuatorLinkage', data, {}, 'POST')

/**
 * 删除设备本地执行器联动
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotActuatorLinkage = (data) => fetch('/Api/Web/IotActuatorLinkage/DelIotActuatorLinkage', data, {}, 'POST')

/**
 * 修改设备本地执行器联动状态
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EidtIotActuatorLinkageStatus = (data) => fetch('/Api/Web/IotActuatorLinkage/EidtIotActuatorLinkageStatus', data, {}, 'POST')

/**
 * 设备本地执行器联动列表
 * @param {object} data {devId}
 * @ignore {devId:设备id}
 */
export const IotActuatorLinkageList = (data) => fetch('/Api/Web/IotActuatorLinkage/IotActuatorLinkageList', data, {}, 'POST')

/**
 * 添加联动控制[前]
 * @param {object} data {companyId, enterpriseId, actuatorIds, status, controlAction, sTime, delayAction, delay, delayUnit, eTime, beginTime, endTime, openPercent, linkageWay, iotControlLinkageConditions}
 * @ignore {companyId:项目Id, enterpriseId:企业Id, actuatorIds:执行器Id, status:状态, controlAction:控制动作, sTime:有效期（开始时间）, delayAction:延时动作, delay:执行时间, delayUnit:执行时间单位, eTime:有效期（结束时间）, beginTime:（执行时间）开始时间, endTime:（执行时间）结束时间, openPercent:开启百分比, linkageWay: , iotControlLinkageConditions:联动条件}
 */
export const AddIotControlLinkage = (data) => fetch('/Api/Web/Web/AddIotControlLinkage', data, {}, 'POST')

/**
 * 添加联动控制并返回id
 * @param {object} data {companyId, enterpriseId, actuatorIds, status, controlAction, sTime, delayAction, delay, delayUnit, eTime, beginTime, endTime, openPercent, linkageWay, iotControlLinkageConditions}
 * @ignore {companyId:项目Id, enterpriseId:企业Id, actuatorIds:执行器Id, status:状态, controlAction:控制动作, sTime:有效期（开始时间）, delayAction:延时动作, delay:执行时间, delayUnit:执行时间单位, eTime:有效期（结束时间）, beginTime:（执行时间）开始时间, endTime:（执行时间）结束时间, openPercent:开启百分比, linkageWay: , iotControlLinkageConditions:联动条件}
 */
export const AddIotControlLinkageId = (data) => fetch('/Api/Web/Web/AddIotControlLinkageId', data, {}, 'POST')

/**
 * 修改联动控制[前]
 * @param {object} data {id, actuatorIds, status, symbols, value, controlAction, sTime, delayAction, delay, delayUnit, eTime, beginTime, endTime, openPercent, linkageWay, iotControlLinkageConditions}
 * @ignore {id:Id, actuatorIds:执行器Id, status:状态, symbols:运算符
1 小于
2 大于, value:值, controlAction:控制动作, sTime:有效期（开始时间）, delayAction:延时动作, delay:执行时间, delayUnit:执行时间单位, eTime:有效期（结束时间）, beginTime:（执行时间）开始时间, endTime:（执行时间）结束时间, openPercent:开启百分比, linkageWay: , iotControlLinkageConditions:联动条件}
 */
export const EditIotControlLinkage = (data) => fetch('/Api/Web/Web/EditIotControlLinkage', data, {}, 'POST')

/**
 * 删除联动控制[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotControlLinkage = (data) => fetch('/Api/Web/Web/DelIotControlLinkage', data, {}, 'POST')

/**
 * 联动控制列表[前]
 * @param {object} data {status, enterpriseId, companyId, pageSize, pageIndex, sorts}
 * @ignore {status:状态, enterpriseId:企业Id, companyId:项目Id, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotControlLinkagePageList = (data) => fetch('/Api/Web/Web/IotControlLinkagePageList', data, {}, 'POST')

/**
 * 联动控制详情[前]
 * @param {object} data {linkageId, actuatorId}
 * @ignore {linkageId:联动控制Id, actuatorId:执行器Id}
 */
export const IotControlLinkageDetail = (data) => fetch('/Api/Web/Web/IotControlLinkageDetail', data, {}, 'POST')

/**
 * 启用或禁用联动控制[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EnableIotControlLinkage = (data) => fetch('/Api/Web/Web/EnableIotControlLinkage', data, {}, 'POST')

/**
 * 添加控制执行计划[前]
 * @param {object} data {actuatorIds, isLoop, name, status, sTime, eTime, controlAction, cron, delayAction, delay, delayUnit, openPercent}
 * @ignore {actuatorIds:执行器Id, isLoop:是否循环, name:计划名称, status:状态 1:启用 2:停用, sTime:有效期（开始时间）, eTime:有效期（结束时间）, controlAction:控制动作, cron:表达式, delayAction:延时动作, delay:延时时间, delayUnit:延时时间单位, openPercent:开启百分比}
 */
export const AddIotControlPlan = (data) => fetch('/Api/Web/Web/AddIotControlPlan', data, {}, 'POST')

/**
 * 修改控制执行计划[前]
 * @param {object} data {planId, isLoop, actuatorIds, name, status, sTime, eTime, controlAction, cron, delayAction, delay, delayUnit, openPercent, id}
 * @ignore {planId:计划Id, isLoop:是否循环, actuatorIds:执行器Id, name:计划名称, status:状态, sTime:有效期（开始时间）, eTime:有效期（结束时间）, controlAction:控制动作, cron:表达式, delayAction:延时动作, delay:延时时间, delayUnit:延时时间单位, openPercent:开启百分比, id:Id}
 */
export const EditIotControlPlan = (data) => fetch('/Api/Web/Web/EditIotControlPlan', data, {}, 'POST')

/**
 * 删除控制执行计划[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotControlPlan = (data) => fetch('/Api/Web/Web/DelIotControlPlan', data, {}, 'POST')

/**
 * 控制执行计划列表[前]
 * @param {object} data {name, enterpriseId, pageSize, pageIndex, sorts}
 * @ignore {name:计划名称, enterpriseId:企业Id, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotControlPlanPageList = (data) => fetch('/Api/Web/Web/IotControlPlanPageList', data, {}, 'POST')

/**
 * 控制执行计划详情[前]
 * @param {object} data {planId, actuatorId}
 * @ignore {planId:计划Id, actuatorId:执行器Id}
 */
export const IotControlPlanDetail = (data) => fetch('/Api/Web/Web/IotControlPlanDetail', data, {}, 'POST')

/**
 * 启用或禁用计划[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EnableIotControlPlan = (data) => fetch('/Api/Web/Web/EnableIotControlPlan', data, {}, 'POST')

/**
 * 天气信息
 */
export const CompanyWeatherDetail = () => fetch('/Api/Web/Web/CompanyWeatherDetail',{}, {}, 'POST')

/**
 * 基地概况[前]
 * @param {object} data {enterpriseId}
 * @ignore {enterpriseId:企业Id(不传自动填充当前用户企业)}
 */
export const EntBaseSurveyList = (data) => fetch('/Api/Web/Web/EntBaseSurveyList', data, {}, 'POST')

/**
 * 设备接入[前]
 * @param {object} data {enterpriseId}
 * @ignore {enterpriseId:企业Id(不传自动填充当前用户企业)}
 */
export const IotDeviceAccessList = (data) => fetch('/Api/Web/Web/IotDeviceAccessList', data, {}, 'POST')

/**
 * 设备快照[前]
 * @param {object} data {paramType, enterpriseId}
 * @ignore {paramType:参数类型 1.内置参数 2.外置参数, enterpriseId:企业Id}
 */
export const IotDeviceSnapshotList = (data) => fetch('/Api/Web/Web/IotDeviceSnapshotList', data, {}, 'POST')

/**
 * 设备下拉选择[前]
 * @param {object} data {devTypeIds, status, enterpriseId}
 * @ignore {devTypeIds:设备类型Id, status:设备状态
1.初始化
2.测试完成
3.已出厂
4.损坏, enterpriseId:企业Id}
 */
export const IotDeviceDropDownList = (data) => fetch('/Api/Web/Web/IotDeviceDropDownList', data, {}, 'POST')

/**
 * 首页智能预警[前]
 */
export const LogIntelligentWarningList = () => fetch('/Api/Web/Web/LogIntelligentWarningList',{}, {}, 'POST')

/**
 * 首页数据分析[前]
 * @param {object} data {devId, devCode, analysisType, paramType}
 * @ignore {devId:设备Id, devCode:设备序列号, analysisType:1:三个月 2:半年内 3:一年内, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceDataAnalysisList = (data) => fetch('/Api/Web/Web/IotDeviceDataAnalysisList', data, {}, 'POST')

/**
 * 企业区域[前]
 * @param {object} data {areaType, enterpriseId}
 * @ignore {areaType: , enterpriseId:企业Id}
 */
export const EntAreaDeviceList = (data) => fetch('/Api/Web/Web/EntAreaDeviceList', data, {}, 'POST')

/**
 * 点位设备[前]
 * @param {object} data {id, paramType}
 * @ignore {id:点位Id, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDevPointDeviceList = (data) => fetch('/Api/Web/Web/IotDevPointDeviceList', data, {}, 'POST')

/**
 * 基地区域信息[前]
 * @param {object} data {enterpriseId}
 * @ignore {enterpriseId:企业Id(不传自动填充当前用户企业)}
 */
export const EntBaseAreaInfo = (data) => fetch('/Api/Web/Web/EntBaseAreaInfo', data, {}, 'POST')

/**
 * 实时数据(页面加载)[前]
 * @param {object} data {devId, devCode, paramType}
 * @ignore {devId:设备Id, devCode:设备序列号, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceRealTimeDataByCacheList = (data) => fetch('/Api/Web/Web/IotDeviceRealTimeDataByCacheList', data, {}, 'POST')

/**
 * 采集历史数据[前]
 * @param {object} data {devId, devCode, sTime, eTime, timeType, paramCode, paramType}
 * @ignore {devId:设备Id, devCode:设备序列号, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, paramCode:参数编码, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceHistoryDataList = (data) => fetch('/Api/Web/Web/IotDeviceHistoryDataList', data, {}, 'POST')

/**
 * 设备预警订阅明细(单设备)[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotDevAlarmSubSingleDetail = (data) => fetch('/Api/Web/Web/IotDevAlarmSubSingleDetail', data, {}, 'POST')

/**
 * 实时数据[前]
 * @param {object} data {devId, devCode, paramType}
 * @ignore {devId:设备Id, devCode:设备序列号, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceRealTimeDataList = (data) => fetch('/Api/Web/Web/IotDeviceRealTimeDataList', data, {}, 'POST')

/**
 * 采集历史数据导出[前]
 * @param {object} data {devId, devCode, sTime, eTime, timeType, paramCode, paramType}
 * @ignore {devId:设备Id, devCode:设备序列号, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, paramCode:参数编码, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceHistoryDataExport = (data) => fetch('/Api/Web/Web/IotDeviceHistoryDataExport', data, {}, 'POST')

/**
 * 修改采集器参数告警阈值[前]
 * @param {object} data {collectorSensorId, alarmMax, alarmMin}
 * @ignore {collectorSensorId:设备采集参数Id, alarmMax:告警值（最大）, alarmMin:告警值（最小）}
 */
export const EditCollectorSensorAlarm = (data) => fetch('/Api/Web/Web/EditCollectorSensorAlarm', data, {}, 'POST')

/**
 * 设备执行器[前]
 * @param {object} data {name, devId, devCode, groupId}
 * @ignore {name:执行器名称, devId:设备Id, devCode:设备序列号, groupId:分组Id}
 */
export const IotDeviceControlList = (data) => fetch('/Api/Web/Web/IotDeviceControlList', data, {}, 'POST')

/**
 * 设备控制[前]
 * @param {object} data {ctrlPwd, id, devCode, actuatorId, exeNo, no, reversalNo, stopNo, openType, percent, delay, onlineStatus, devId, delayOpenType}
 * @ignore {ctrlPwd:控制密码, id:设备Id, devCode:设备编号, actuatorId:执行器Id, exeNo:执行器编号, no:正转通道号, reversalNo:反转通道号(0表示无效), stopNo:停止通道号(0表示无效), openType: , percent:控制百分比, delay:延迟时长, onlineStatus:在线状态, devId:设备id, delayOpenType:关闭指令}
 */
export const IotDeviceCtrlChannel = (data) => fetch('/Api/Web/Web/IotDeviceCtrlChannel', data, {}, 'POST')

/**
 * 设备批量控制[前]
 * @param {object} data {ctrlPwd, actuators, openPercent}
 * @ignore {ctrlPwd:控制密码, actuators:执行器, openPercent:开启百分比}
 */
export const IotDeviceCtrlMany = (data) => fetch('/Api/Web/Web/IotDeviceCtrlMany', data, {}, 'POST')

/**
 * 执行器相关执行计划明细[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotActuatorControlDetail = (data) => fetch('/Api/Web/Web/IotActuatorControlDetail', data, {}, 'POST')

/**
 * 设备采集器参数列表(多设备)[前]
 * @param {object} data {paramType, enterpriseId, devId, devCode, name}
 * @ignore {paramType:参数类型 1.内置参数 2.外置参数, enterpriseId:企业Id, devId:设备id, devCode:设备code, name:查询名称}
 */
export const IotDevSensorChooseList = (data) => fetch('/Api/Web/Web/IotDevSensorChooseList', data, {}, 'POST')

/**
 * 设备视频数据[前]
 * @param {object} data {id, channelNo}
 * @ignore {id:设备Id, channelNo:通道号}
 */
export const IotVideoDeviceDataList = (data) => fetch('/Api/Web/Web/IotVideoDeviceDataList', data, {}, 'POST')

/**
 * 基地视频设备[前]
 * @param {object} data {enterpriseId}
 * @ignore {enterpriseId:企业Id(不传自动填充当前用户企业)}
 */
export const IotBaseVideoList = (data) => fetch('/Api/Web/Web/IotBaseVideoList', data, {}, 'POST')

/**
 * 视频设备控制[前]
 * @param {object} data {devId, devCode, channelNo, ctrlType, speed, ctrlPwd, isCtrlPwd}
 * @ignore {devId:设备Id, devCode:设备序列号, channelNo:通道号, ctrlType: , speed:云台速度：0-慢，1-适中，2-快，海康设备参数不可为0, ctrlPwd:控制密码, isCtrlPwd:是否需要控制密码 默认必须控制，后端使用时为false}
 */
export const IotDeviceVideoCtrl = (data) => fetch('/Api/Web/Web/IotDeviceVideoCtrl', data, {}, 'POST')

/**
 * 视频设备翻转控制[前]
 * @param {object} data {devId, devCode, channelNo, ctrlType, ctrlPwd}
 * @ignore {devId:设备Id, devCode:设备序列号, channelNo:通道号, ctrlType: , ctrlPwd:控制密码}
 */
export const IotDeviceVideoMirrorCtrl = (data) => fetch('/Api/Web/Web/IotDeviceVideoMirrorCtrl', data, {}, 'POST')

/**
 * 视频设备控制开始[前]
 * @param {object} data {devId, devCode, channelNo, ctrlType, speed, ctrlPwd, isCtrlPwd}
 * @ignore {devId:设备Id, devCode:设备序列号, channelNo:通道号, ctrlType: , speed:云台速度：0-慢，1-适中，2-快，海康设备参数不可为0, ctrlPwd:控制密码, isCtrlPwd:是否需要控制密码 默认必须控制，后端使用时为false}
 */
export const IotDeviceVideoCtrlStart = (data) => fetch('/Api/Web/Web/IotDeviceVideoCtrlStart', data, {}, 'POST')

/**
 * 视频设备控制停止[前]
 * @param {object} data {devId, devCode, channelNo, ctrlType, speed, ctrlPwd, isCtrlPwd}
 * @ignore {devId:设备Id, devCode:设备序列号, channelNo:通道号, ctrlType: , speed:云台速度：0-慢，1-适中，2-快，海康设备参数不可为0, ctrlPwd:控制密码, isCtrlPwd:是否需要控制密码 默认必须控制，后端使用时为false}
 */
export const IotDeviceVideoCtrlStop = (data) => fetch('/Api/Web/Web/IotDeviceVideoCtrlStop', data, {}, 'POST')

/**
 * 视频设备抓拍[前]
 * @param {object} data {devId, devCode, channelNo, ctrlPwd, isCtrlPwd}
 * @ignore {devId:设备Id, devCode:设备序列号, channelNo:通道号, ctrlPwd:控制密码, isCtrlPwd:是否需要控制密码 默认必须控制，后端使用时为false}
 */
export const IotDeviceVideoPhotoCapture = (data) => fetch('/Api/Web/Web/IotDeviceVideoPhotoCapture', data, {}, 'POST')

/**
 * 设备在线状态
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotDeviceOnlineStatus = (data) => fetch('/Api/Web/Web/IotDeviceOnlineStatus', data, {}, 'POST')

/**
 * 视频设备开/关控制[前]
 * @param {object} data {ctrlPwd, id, devCode, actuatorId, exeNo, no, reversalNo, stopNo, openType, percent, delay, onlineStatus, devId, delayOpenType}
 * @ignore {ctrlPwd:控制密码, id:设备Id, devCode:设备编号, actuatorId:执行器Id, exeNo:执行器编号, no:正转通道号, reversalNo:反转通道号(0表示无效), stopNo:停止通道号(0表示无效), openType: , percent:控制百分比, delay:延迟时长, onlineStatus:在线状态, devId:设备id, delayOpenType:关闭指令}
 */
export const IotDeviceVideoOnOffCtrl = (data) => fetch('/Api/Web/Web/IotDeviceVideoOnOffCtrl', data, {}, 'POST')

/**
 * 设备视频地址列表
 * @param {object} data {devId, channelNo, addrType, type}
 * @ignore {devId:设备Id, channelNo:通道号, addrType:地址类型 1-ezopen、2-hls、3-rtmp、4-flv (大华只有2,3), type:是否强制更新}
 */
export const IotVideoAddrPlayList = (data) => fetch('/Api/Web/Web/IotVideoAddrPlayList', data, {}, 'POST')

/**
 * 视频设备图片[前]
 * @param {object} data {devId, sTime, eTime, timeType, pageSize, pageIndex, sorts}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotDevicePhotoPageList = (data) => fetch('/Api/Web/Web/IotDevicePhotoPageList', data, {}, 'POST')

/**
 * 基地区域[前]
 * @param {object} data {category, areaType, devTypes, isLive, enterpriseId}
 * @ignore {category:基地类别（1：种养基地，2：加工基地；数据来源数据字典）, areaType: , devTypes:设备类型, isLive:是否直播视频设备, enterpriseId:企业Id}
 */
export const EntBaseAreaList = (data) => fetch('/Api/Web/Web/EntBaseAreaList', data, {}, 'POST')

/**
 * 设备同比分析[前]
 * @param {object} data {devIds, sTime, eTime, timeType, paramType}
 * @ignore {devIds:设备序列号, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceYoyAnalysis = (data) => fetch('/Api/Web/Web/IotDeviceYoyAnalysis', data, {}, 'POST')

/**
 * 设备环比分析[前]
 * @param {object} data {devId, dates, paramType}
 * @ignore {devId:设备Id, dates:时间(月), paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotDeviceQoqAnalysis = (data) => fetch('/Api/Web/Web/IotDeviceQoqAnalysis', data, {}, 'POST')

/**
 * 动物称重信息分析[前]
 * @param {object} data {baseId, companyId, enterpriseId}
 * @ignore {baseId:基地, companyId:项目Id, enterpriseId:企业Id}
 */
export const TraAnimalInfoAnalysis = (data) => fetch('/Api/Web/Web/TraAnimalInfoAnalysis', data, {}, 'POST')

/**
 * 添加视频看板[前]
 * @param {object} data {order, devId, type}
 * @ignore {order:排序, devId:设备Id, type:类型：1 4宫格
2.6宫格
3.9宫格}
 */
export const AddIotVideoBoard = (data) => fetch('/Api/Web/Web/AddIotVideoBoard', data, {}, 'POST')

/**
 * 删除视频看板[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotVideoBoard = (data) => fetch('/Api/Web/Web/DelIotVideoBoard', data, {}, 'POST')

/**
 * 视频看板列表[前]
 * @param {object} data {type}
 * @ignore {type:类型：1 4宫格
2.6宫格
3.9宫格}
 */
export const IotVideoBoardList = (data) => fetch('/Api/Web/Web/IotVideoBoardList', data, {}, 'POST')

/**
 * 杀虫灯信息读取
 * @param {object} data {devId, type}
 * @ignore {devId:设备Id, type:读取参数类型：如 status、data、paramconf 等}
 */
export const IotInsectLampRead = (data) => fetch('/Api/Web/Web/IotInsectLampRead', data, {}, 'POST')

/**
 * 杀虫灯数据图表
 * @param {object} data {devId, sTime, eTime, timeType}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月}
 */
export const IotInsectLampData = (data) => fetch('/Api/Web/Web/IotInsectLampData', data, {}, 'POST')

/**
 * 杀虫灯数据表格
 * @param {object} data {devId, sTime, eTime, timeType, pageSize, pageIndex, sorts}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotInsectLampDataTable = (data) => fetch('/Api/Web/Web/IotInsectLampDataTable', data, {}, 'POST')

/**
 * 杀虫灯图表数据导出
 * @param {object} data {devId, sTime, eTime, timeType}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月}
 */
export const IotInsectLampDataExport = (data) => fetch('/Api/Web/Web/IotInsectLampDataExport', data, {}, 'POST')

/**
 * 基地区域设备(基地-区域-设备)[前]
 * @param {object} data {devTypes, devName, devCode, isLive, enterpriseId}
 * @ignore {devTypes:设备类型, devName:设备名称, devCode:设备序列号, isLive:是否直播视频设备, enterpriseId:企业Id}
 */
export const EntBaseAreaDeviceList = (data) => fetch('/Api/Web/Web/EntBaseAreaDeviceList', data, {}, 'POST')

/**
 * 基地区域设备(基地-设备)[前]
 * @param {object} data {devTypes, devName, devCode, isLive, enterpriseId}
 * @ignore {devTypes:设备类型, devName:设备名称, devCode:设备序列号, isLive:是否直播视频设备, enterpriseId:企业Id}
 */
export const BaseDeviceList = (data) => fetch('/Api/Web/Web/BaseDeviceList', data, {}, 'POST')

/**
 * 分组设备(分组-设备)[前]
 * @param {object} data {devTypes, devName, devCode, isLive, enterpriseId, actuatorFun}
 * @ignore {devTypes:设备类型, devName:设备名称, devCode:设备序列号, isLive:是否直播视频设备, enterpriseId:企业Id, actuatorFun:设备功能 1 采集 2 控制 4 拍照 为空反所有}
 */
export const GroupDeviceList = (data) => fetch('/Api/Web/Web/GroupDeviceList', data, {}, 'POST')

/**
 * 添加设备分组[前]
 * @param {object} data {name, order, companyId, enterpriseId}
 * @ignore {name:名称, order:排序, companyId:项目ID, enterpriseId:企业ID}
 */
export const AddFrontIotDevGroup = (data) => fetch('/Api/Web/Web/AddFrontIotDevGroup', data, {}, 'POST')

/**
 * 添加设备分组并返回详情[前]
 * @param {object} data {name, order, companyId, enterpriseId}
 * @ignore {name:名称, order:排序, companyId:项目ID, enterpriseId:企业ID}
 */
export const AddIotDevGroupDetails = (data) => fetch('/Api/Web/Web/AddIotDevGroupDetails', data, {}, 'POST')

/**
 * 修改设备分组[前]
 * @param {object} data {id, name, order, enterpriseId}
 * @ignore {id:Id, name:名称, order:排序, enterpriseId:企业Id}
 */
export const EditFrontIotDevGroup = (data) => fetch('/Api/Web/Web/EditFrontIotDevGroup', data, {}, 'POST')

/**
 * 分组控制设备[前]
 * @param {object} data {enterpriseId}
 * @ignore {enterpriseId:企业Id(不传自动填充当前用户企业)}
 */
export const IotGroupDeviceList = (data) => fetch('/Api/Web/Web/IotGroupDeviceList', data, {}, 'POST')

/**
 * 基地设备[前]
 * @param {object} data {enterpriseId, companyId, name, pageSize, pageIndex, sorts}
 * @ignore {enterpriseId:企业ID, companyId:项目Id, name:搜索名称, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const EntBaseDeviceList = (data) => fetch('/Api/Web/Web/EntBaseDeviceList', data, {}, 'POST')

/**
 * 配置设备预警订阅(多设备)[前]
 * @param {object} data {devId, isReceive, currentDayRec, collectorIds}
 * @ignore {devId:设备, isReceive:是否接收 1.是 , 2.否, currentDayRec:当日是否再次接收, collectorIds:传感器Id}
 */
export const SetIotDevAlarmSub = (data) => fetch('/Api/Web/Web/SetIotDevAlarmSub', data, {}, 'POST')

/**
 * 配置设备预警订阅(单设备)[前]
 * @param {object} data {devId, isReceive, currentDayRec, collectorIds}
 * @ignore {devId:设备, isReceive:是否接收 1.是 , 2.否, currentDayRec:当日是否再次接收, collectorIds:传感器Id}
 */
export const SetSingleIotDevAlarmSub = (data) => fetch('/Api/Web/Web/SetSingleIotDevAlarmSub', data, {}, 'POST')

/**
 * 配置今日是否订阅[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const SetToDaySub = (data) => fetch('/Api/Web/Web/SetToDaySub', data, {}, 'POST')

/**
 * 配置是否接收[前]
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const SetReceiveSub = (data) => fetch('/Api/Web/Web/SetReceiveSub', data, {}, 'POST')

/**
 * 设备预警订阅明细(用户)[前]
 */
export const IotDevAlarmSubDetail = () => fetch('/Api/Web/Web/IotDevAlarmSubDetail',{}, {}, 'POST')

/**
 * 设备采集器参数列表[前]
 * @param {object} data {id, paramType}
 * @ignore {id:设备Id, paramType:参数类型 1.内置参数 2.外置参数}
 */
export const IotCollectorSensorChooseList = (data) => fetch('/Api/Web/Web/IotCollectorSensorChooseList', data, {}, 'POST')

/**
 * 人员订阅策略配置[前]
 * @param {object} data {subType, receiveMode, subTimes}
 * @ignore {subType:订阅类型
1.环境参数告警
2.离线告警
4.流量告警, receiveMode:接收方式
1.App
2.短信
4.邮件
8.语音电话, subTimes:订阅策略时间}
 */
export const SetComAdminSub = (data) => fetch('/Api/Web/Web/SetComAdminSub', data, {}, 'POST')

/**
 * 告警日志列表[前]
 * @param {object} data {devCode, paramCode, alarmType, sTime, eTime, timeType, isDispose, isView, pageSize, pageIndex, sorts}
 * @ignore {devCode:设备Id, paramCode:参数编号, alarmType:告警类型
1.参数告警
2.参数错误告警（只给内部人员告警）
3.设备离线, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1.当天 2.三天 3.7天 4.近1月 5.近三月, isDispose:是否处理, isView:是否查看, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const LogAlarmList = (data) => fetch('/Api/Web/Web/LogAlarmList', data, {}, 'POST')

/**
 * FTP配置[前]
 * @param {object} data {ftpIP, ftpPort, ftpUser, ftpPwd, filePrefixCode, fileSite, companyId}
 * @ignore {ftpIP:FTPIP, ftpPort:FTPPort, ftpUser:FTP登录名, ftpPwd:Ftp密码, filePrefixCode:文件夹前缀
整个数据库不能重复, fileSite:文件站点, companyId:项目Id}
 */
export const SetComFtpConfig = (data) => fetch('/Api/Web/Web/SetComFtpConfig', data, {}, 'POST')

/**
 * 项目FTP配置明细[前]
 */
export const ComFtpConfigDetail = () => fetch('/Api/Web/Web/ComFtpConfigDetail',{}, {}, 'POST')

/**
 * 设置批量日志已读
 * @param {object} data {ids}
 * @ignore {ids:告警列表ID}
 */
export const LogAlarmVolumeRead = (data) => fetch('/Api/Web/Web/LogAlarmVolumeRead', data, {}, 'POST')

/**
 * 动物信息明细
 * @param {object} data {earCode, animalId, diseaseType, enterpriseId, areaId}
 * @ignore {earCode:耳标号, animalId:动物ID, diseaseType:病疫类型
1.防疫
2.疾病, enterpriseId:企业Id, areaId:区域id}
 */
export const TraAnimalInfoDetail = (data) => fetch('/Api/Web/Web/TraAnimalInfoDetail', data, {}, 'POST')

/**
 * 展示当前基地动物数量、异常、佩戴定位设备信息
 * @param {object} data {baseId}
 * @ignore {baseId:基地id不传显示当前所有}
 */
export const TraBaseAnimalInfo = (data) => fetch('/Api/Web/Web/TraBaseAnimalInfo', data, {}, 'POST')

/**
 * 通过基地id获取电子围栏信息 不传获取全部
 * @param {object} data {id, areaId}
 * @ignore {id:Id, areaId:区域id}
 */
export const TraFenceDetailed = (data) => fetch('/Api/Web/Web/TraFenceDetailed', data, {}, 'POST')

/**
 * 通过基地id查询动物信息不传显示所有
 * @param {object} data {id, areaId}
 * @ignore {id:Id, areaId:区域id}
 */
export const TraBaseAreaAnimalList = (data) => fetch('/Api/Web/Web/TraBaseAreaAnimalList', data, {}, 'POST')

/**
 * 虫情图片数据列表（不分页）
 * @param {object} data {imgId}
 * @ignore {imgId:图片Id}
 */
export const IotInsectImgDataList = (data) => fetch('/Api/Web/Insect/IotInsectImgDataList', data, {}, 'POST')

/**
 * 病虫害防治百科(物联网平台使用)
 * @param {object} data {name}
 * @ignore {name:名称}
 */
export const SysPestControlIotDetail = (data) => fetch('/Api/Web/Insect/SysPestControlIotDetail', data, {}, 'POST')

/**
 * 新增虫情图片数据
 * @param {object} data {num, imgId, libId}
 * @ignore {num:数量, imgId:图片Id, libId:虫库Id}
 */
export const AddIotInsectImgData = (data) => fetch('/Api/Web/Insect/AddIotInsectImgData', data, {}, 'POST')

/**
 * 虫库选择列表
 */
export const IotInsectLibChooseList = () => fetch('/Api/Web/Insect/IotInsectLibChooseList',{}, {}, 'POST')

/**
 * 获取告警内容列表
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const LogInsectAlarmContentList = (data) => fetch('/Api/Web/Insect/LogInsectAlarmContentList', data, {}, 'POST')

/**
 * 获取前6条设备告警内容(物联网前端)
 * @param {object} data {id}
 * @ignore {id:设备id}
 */
export const LogInsectDevAlarmContentList = (data) => fetch('/Api/Web/Insect/LogInsectDevAlarmContentList', data, {}, 'POST')

/**
 * 控制器列表("300012")
 * @param {object} data {devId, name, executorTypeId, pageSize, pageIndex, sorts}
 * @ignore {devId:DevId, name:名称, executorTypeId:执行器类型, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotControlActuatorPageList = (data) => fetch('/Api/Web/IotControlActuator/IotControlActuatorPageList', data, {}, 'POST')

/**
 * 控制器选择列表(多字段)
 * @param {object} data {devId, devName, devCode, actuatorName, selectIds, pageSize, pageIndex, sorts}
 * @ignore {devId:设备Id, devName:设备名称, devCode:设备编号, actuatorName:执行器名称, selectIds:选中Id, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotControlActuatorChooseList = (data) => fetch('/Api/Web/IotControlActuator/IotControlActuatorChooseList', data, {}, 'POST')

/**
 * 控制器选择列表(树形)
 * @param {object} data {devName, devCode, executorTypeId, isDevNew, funTypeId, taskId}
 * @ignore {devName:设备名称, devCode:设备编号, executorTypeId:执行器类型, isDevNew:true 新设备 false 为全部设备, funTypeId:设备功能id, taskId:任务id}
 */
export const IotControlActuatorTreeList = (data) => fetch('/Api/Web/IotControlActuator/IotControlActuatorTreeList', data, {}, 'POST')

/**
 * 控制器类型选择列表
 * @param {object} data {devName, devCode, executorTypeId, isDevNew, funTypeId, taskId}
 * @ignore {devName:设备名称, devCode:设备编号, executorTypeId:执行器类型, isDevNew:true 新设备 false 为全部设备, funTypeId:设备功能id, taskId:任务id}
 */
export const IotControlActuatorGroupList = (data) => fetch('/Api/Web/IotControlActuator/IotControlActuatorGroupList', data, {}, 'POST')

/**
 * 控制器详情
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotControlActuatorDetail = (data) => fetch('/Api/Web/IotControlActuator/IotControlActuatorDetail', data, {}, 'POST')

/**
 * 修改控制器名称
 * @param {object} data {name, id}
 * @ignore {name:控制器名称, id:Id}
 */
export const EditIotControlActuatorName = (data) => fetch('/Api/Web/IotControlActuator/EditIotControlActuatorName', data, {}, 'POST')

/**
 * 根据执行器ID查询功能类型树菜单
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotExecutorTypeTreeList = (data) => fetch('/Api/Web/IotControlActuator/IotExecutorTypeTreeList', data, {}, 'POST')

/**
 * 新增联动条件
 * @param {object} data {sensorId, value, compareType, taskLinkageId}
 * @ignore {sensorId:(条件)传感器Id, value:(条件)值, compareType: , taskLinkageId:(条件)任务联动Id}
 */
export const AddIotLinkageCondition = (data) => fetch('/Api/Web/IotLinkageCondition/AddIotLinkageCondition', data, {}, 'POST')

/**
 * 编辑联动条件
 * @param {object} data {sensorId, value, compareType, taskLinkageId, id}
 * @ignore {sensorId: , value:(条件)值, compareType: , taskLinkageId:(条件)任务联动Id, id:Id}
 */
export const EditIotLinkageCondition = (data) => fetch('/Api/Web/IotLinkageCondition/EditIotLinkageCondition', data, {}, 'POST')

/**
 * 删除联动条件
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotLinkageCondition = (data) => fetch('/Api/Web/IotLinkageCondition/DelIotLinkageCondition', data, {}, 'POST')

/**
 * 联动条件列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotLinkageConditionList = (data) => fetch('/Api/Web/IotLinkageCondition/IotLinkageConditionList', data, {}, 'POST')

/**
 * 新增任务
 * @param {object} data {name, companyId, enterpriseId}
 * @ignore {name:名称, companyId:项目Id, enterpriseId:企业Id}
 */
export const AddIotTask = (data) => fetch('/Api/Web/IotTask/AddIotTask', data, {}, 'POST')

/**
 * 编辑任务
 * @param {object} data {name, companyId, enterpriseId, id}
 * @ignore {name:任务名称, companyId:项目Id, enterpriseId:企业Id, id:Id}
 */
export const EditIotTask = (data) => fetch('/Api/Web/IotTask/EditIotTask', data, {}, 'POST')

/**
 * 删除任务
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotTask = (data) => fetch('/Api/Web/IotTask/DelIotTask', data, {}, 'POST')

/**
 * 任务列表（不分页）
 * @param {object} data {name}
 * @ignore {name:任务名称}
 */
export const IotTaskList = (data) => fetch('/Api/Web/IotTask/IotTaskList', data, {}, 'POST')

/**
 * 新增任务定时
 * @param {object} data {companyId, cron, enterpriseId, taskId, status}
 * @ignore {companyId:项目Id, cron:表达式, enterpriseId:企业Id, taskId:任务Id, status:状态}
 */
export const AddIotTaskTimer = (data) => fetch('/Api/Web/IotTaskTimer/AddIotTaskTimer', data, {}, 'POST')

/**
 * 编辑任务定时
 * @param {object} data {cron, taskId, status, id}
 * @ignore {cron:表达式, taskId:任务Id, status:状态, id:Id}
 */
export const EditIotTaskTimer = (data) => fetch('/Api/Web/IotTaskTimer/EditIotTaskTimer', data, {}, 'POST')

/**
 * 编辑任务定时状态
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const EditIotTaskTimerStatus = (data) => fetch('/Api/Web/IotTaskTimer/EditIotTaskTimerStatus', data, {}, 'POST')

/**
 * 删除任务定时
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotTaskTimer = (data) => fetch('/Api/Web/IotTaskTimer/DelIotTaskTimer', data, {}, 'POST')

/**
 * 任务定时列表
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskTimerList = (data) => fetch('/Api/Web/IotTaskTimer/IotTaskTimerList', data, {}, 'POST')

/**
 * 新增任务联动
 * @param {object} data {status, enterpriseId, companyId, beginTime, endTime, taskId, linkageWay, addIotLinkageConditionDtos}
 * @ignore {status:状态, enterpriseId:企业Id, companyId:项目Id, beginTime:（执行时间）开始时间
未设置表示长期有效, endTime:（执行时间）结束时间
未设置表示长期有效, taskId:任务命令Id, linkageWay: , addIotLinkageConditionDtos:添加联动条件}
 */
export const AddIotTaskLinkage = (data) => fetch('/Api/Web/IotTaskLinkage/AddIotTaskLinkage', data, {}, 'POST')

/**
 * 编辑任务联动
 * @param {object} data {status, beginTime, endTime, linkageWay, taskId, addIotLinkageConditionDtos, id}
 * @ignore {status:状态, beginTime:（执行时间）开始时间
未设置表示长期有效, endTime:（执行时间）结束时间
未设置表示长期有效, linkageWay: , taskId:任务名称, addIotLinkageConditionDtos:添加联动条件, id:Id}
 */
export const EditIotTaskLinkage = (data) => fetch('/Api/Web/IotTaskLinkage/EditIotTaskLinkage', data, {}, 'POST')

/**
 * 删除任务联动
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotTaskLinkage = (data) => fetch('/Api/Web/IotTaskLinkage/DelIotTaskLinkage', data, {}, 'POST')

/**
 * 任务联动状态更改
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskLinkageStatus = (data) => fetch('/Api/Web/IotTaskLinkage/IotTaskLinkageStatus', data, {}, 'POST')

/**
 * 任务联动列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskLinkageList = (data) => fetch('/Api/Web/IotTaskLinkage/IotTaskLinkageList', data, {}, 'POST')

/**
 * 任务联动列表（不分页带设备树菜单）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskLinkageTreeList = (data) => fetch('/Api/Web/IotTaskLinkage/IotTaskLinkageTreeList', data, {}, 'POST')

/**
 * 新增任务命令
 * @param {object} data {taskId, taskDelay, controlAction, openPercent, delayAction, delay, delayUnit, enterpriseId, companyId, actuatorIds}
 * @ignore {taskId:任务Id, taskDelay:任务延时
多少分钟开始此任务
0 为立即开始, controlAction:控制动作, openPercent:开启百分比, delayAction:执行动作, delay:执行时间, delayUnit:执行时间单位, enterpriseId:企业Id, companyId:项目Id, actuatorIds:执行器数组ID}
 */
export const AddIotTaskCmd = (data) => fetch('/Api/Web/IotTaskCmd/AddIotTaskCmd', data, {}, 'POST')

/**
 * 编辑任务命令
 * @param {object} data {taskId, taskDelay, controlAction, openPercent, delayAction, delay, delayUnit, order, actuatorId, id}
 * @ignore {taskId:任务Id, taskDelay:任务延时
多少分钟开始此任务
0 为立即开始, controlAction:控制动作, openPercent:开启百分比, delayAction:执行动作, delay:执行时间, delayUnit:执行时间单位, order:排序, actuatorId:执行器, id:Id}
 */
export const EditIotTaskCmd = (data) => fetch('/Api/Web/IotTaskCmd/EditIotTaskCmd', data, {}, 'POST')

/**
 * 删除任务命令
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotTaskCmd = (data) => fetch('/Api/Web/IotTaskCmd/DelIotTaskCmd', data, {}, 'POST')

/**
 * 任务命令列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskCmdList = (data) => fetch('/Api/Web/IotTaskCmd/IotTaskCmdList', data, {}, 'POST')

/**
 * 任务命令选择列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotTaskCmdChooseList = (data) => fetch('/Api/Web/IotTaskCmd/IotTaskCmdChooseList', data, {}, 'POST')

/**
 * 修改任务排序
 * @param {object} data {ids}
 * @ignore {ids:多个Id}
 */
export const EditIotTaskCmdControlOrder = (data) => fetch('/Api/Web/IotTaskCmd/EditIotTaskCmdControlOrder', data, {}, 'POST')

/**
 * 任务命令控制（传数组命令id）
 * @param {object} data {taskCmdId, actuatorIds}
 * @ignore {taskCmdId:订阅唯一ID, actuatorIds:命令id}
 */
export const IotTaskCmdControl = (data) => fetch('/Api/Web/IotTaskCmd/IotTaskCmdControl', data, {}, 'POST')

/**
 * 任务命令反转控制
 * @param {object} data {taskCmdId, actuatorIds}
 * @ignore {taskCmdId:订阅唯一ID, actuatorIds:命令id}
 */
export const IotTaskCmdReversalControl = (data) => fetch('/Api/Web/IotTaskCmd/IotTaskCmdReversalControl', data, {}, 'POST')

/**
 * 关闭当前任务
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotLinkageCloseTask = (data) => fetch('/Api/Web/IotTaskCmd/IotLinkageCloseTask', data, {}, 'POST')

/**
 * 杀虫灯数据分析
 * @param {object} data {devId, sTime, eTime, timeType}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1当天 2三天 3本周 4本月 5三月 6半年}
 */
export const IotInsectLampData1Analyse = (data) => fetch('/Api/Web/InsectLamp/IotInsectLampData1Analyse', data, {}, 'POST')

/**
 * 杀虫灯数据(月度)分析
 * @param {object} data {devId, sTime, eTime, timeType}
 * @ignore {devId:设备Id, sTime:开始时间, eTime:结束时间, timeType:查询时间类型 1当天 2三天 3本周 4本月 5三月 6半年}
 */
export const IotInsectLampDataAnalyse = (data) => fetch('/Api/Web/InsectLamp/IotInsectLampDataAnalyse', data, {}, 'POST')

/**
 * 获取当前设备杀虫总数
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotInsectLampNumber = (data) => fetch('/Api/Web/InsectLamp/IotInsectLampNumber', data, {}, 'POST')

/**
 * 执行器功能列表(快捷功能)
 * @param {object} data {name, actuatorId}
 * @ignore {name:名称, actuatorId:执行器Id}
 */
export const IotActuatorFunList = (data) => fetch('/Api/Web/IotActuatorFun/IotActuatorFunList', data, {}, 'POST')

/**
 * 执行器功能列表（带全功能）
 * @param {object} data {name, actuatorId}
 * @ignore {name:名称, actuatorId:执行器Id}
 */
export const IotActuatorFunWholeList = (data) => fetch('/Api/Web/IotActuatorFun/IotActuatorFunWholeList', data, {}, 'POST')

/**
 * 执行器类型功能选择列表
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotExecutorTypeFunChooseList = (data) => fetch('/Api/Web/IotExecutorTypeFun/IotExecutorTypeFunChooseList', data, {}, 'POST')

/**
 * 执行器类型功能列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotExecutorTypeFunList = (data) => fetch('/Api/Web/IotExecutorTypeFun/IotExecutorTypeFunList', data, {}, 'POST')

/**
 * 执行器类型管理列表
 * @param {object} data {name, code, pageSize, pageIndex, sorts}
 * @ignore {name:名称, code:编码, pageSize:每页的的数据条数, pageIndex:页号(大于等于1), sorts: }
 */
export const IotExecutorTypePageList = (data) => fetch('/Api/Web/IotExecutorType/IotExecutorTypePageList', data, {}, 'POST')

/**
 * 执行器类型管理选择列表
 */
export const IotExecutorTypeDropList = () => fetch('/Api/Web/IotExecutorType/IotExecutorTypeDropList',{}, {}, 'POST')

/**
 * 执行器类型管理选择列表(物联网前端专用)
 */
export const IotExecutorTypeWebDropList = () => fetch('/Api/Web/IotExecutorType/IotExecutorTypeWebDropList',{}, {}, 'POST')

/**
 * 执行器功能(集合)列表根据执行器类型查询功能
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotActuatorFunAggregateList = (data) => fetch('/Api/Web/IotExecutorType/IotActuatorFunAggregateList', data, {}, 'POST')

/**
 * 新增执行器联动条件
 * @param {object} data {sensorId, value, compareType, controlLinkageId}
 * @ignore {sensorId:传感器Id, value:值, compareType: , controlLinkageId:任务联动Id}
 */
export const AddIotControlLinkageCondition = (data) => fetch('/Api/Web/IotControlLinkageCondition/AddIotControlLinkageCondition', data, {}, 'POST')

/**
 * 编辑执行器联动条件
 * @param {object} data {sensorId, value, compareType, controlLinkageId, id}
 * @ignore {sensorId:传感器Id, value:值, compareType: , controlLinkageId:任务联动Id, id:Id}
 */
export const EditIotControlLinkageCondition = (data) => fetch('/Api/Web/IotControlLinkageCondition/EditIotControlLinkageCondition', data, {}, 'POST')

/**
 * 删除执行器联动条件
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const DelIotControlLinkageCondition = (data) => fetch('/Api/Web/IotControlLinkageCondition/DelIotControlLinkageCondition', data, {}, 'POST')

/**
 * 执行器联动条件列表（不分页）
 * @param {object} data {id}
 * @ignore {id:Id}
 */
export const IotControlLinkageConditionList = (data) => fetch('/Api/Web/IotControlLinkageCondition/IotControlLinkageConditionList', data, {}, 'POST')

